import {Connection, PublicKey, SYSVAR_CLOCK_PUBKEY} from "@solana/web3.js";
import {LendingMarket, Reserve, ReserveParser, Detail, TokenAccountParser, isReserve} from "../models";
import {getConnection} from "../context/connection";
import {
    ALL_IDS,
    RESERVE_IDS,
    RESERVE_LARIX_ORACLES,
    RESERVE_NAMES,
    RESERVE_FULLNAMES,
    LP_RESERVE_IDS, LENDING_ID, LENDING_ARRAY
} from "@/api/utils/ids";
import BN from "bn.js";
import BigNumber from "bignumber.js";
import {getMineRatio, getUtilizationRate} from "@/api/utils/calculateAllMine";
import {BIG_NUMBER_WAD, ZERO} from "@/api/constants";
import {SLOTS_PER_YEAR,REAL_SLOTS_PER_YEAR, LP_REWARD_TOKEN} from "@/api/constants/utils";
import {BIG_NUMBER_ONE, BIG_NUMBER_ZERO, eX} from "@/utils/helpers";
import {isMarketPrice, MarketPrice, PriceParser} from "@/api/models/state/marketPrice";
import {AmmParser} from "@/api/models/state/lp-price/amm";
import {MintLayout} from "@solana/spl-token";
import {MintParser} from "@/api/models/state/mint";
import {AmmOpenOrdersLayoutParser} from "@/api/models/state/lp-price/ammOpenOrders";
import {RAYDIUM_ACCOUNT_LAYOUT, STAKE_INFO_LAYOUT, STAKE_INFO_LAYOUT_V4 } from "../models/state/lp-price/poolInfo";
let lastSlot:number = 0
let firstQuery = true
export async function getLendingReserve(): Promise<Array<Detail<Reserve>>>{
    const reserveArrayInner = new Array<Detail<Reserve>>();
    const marketPriceArray = new Array<Detail<MarketPrice>>();
    const connection = await getConnection()
    // @ts-ignore
    const result = await Promise.all(
            [
                getSlot(connection),
                getAllLendingReserveAndMarketPrice(connection,reserveArrayInner),
                // getLendingReserveByKey(connection,RESERVE_IDS[0],RESERVE_NAMES[0],reserveArrayInner,marketPriceArray),
                // getMarketPrice(connection,0,reserveArrayInner,marketPriceArray),
                // getLendingReserveByKey(connection,RESERVE_IDS[1],RESERVE_NAMES[1],reserveArrayInner,marketPriceArray),
                // getMarketPrice(connection,1,reserveArrayInner,marketPriceArray),
                // getLendingReserveByKey(connection,RESERVE_IDS[2],RESERVE_NAMES[2],reserveArrayInner,marketPriceArray),
                // getMarketPrice(connection,2,reserveArrayInner,marketPriceArray),
                // getLendingReserveByKey(connection,RESERVE_IDS[3],RESERVE_NAMES[3],reserveArrayInner,marketPriceArray),
                // getMarketPrice(connection,3,reserveArrayInner,marketPriceArray),
                // getLendingReserveByKey(connection,RESERVE_IDS[4],RESERVE_NAMES[4],reserveArrayInner,marketPriceArray),
                // getMarketPrice(connection,4,reserveArrayInner,marketPriceArray),
            ]
        )
    ;

    lastSlot = lastSlot < result[0]?result[0]:lastSlot;
    const currentSlot = new BN(lastSlot)
    accrueInterest(reserveArrayInner,currentSlot)
    refreshIndex(reserveArrayInner,currentSlot)
    refreshExchangeRate(reserveArrayInner)
    firstQuery = false
    return reserveArrayInner
}
function refreshExchangeRate(allReserve:Detail<Reserve>[]) {
    allReserve.map((reserve)=> {
        const info = reserve.info
        const decimals = info.liquidity.mintDecimals
        let totalBorrowedAmount = eX(info.liquidity.borrowedAmountWads.toString(), -18)
        if (totalBorrowedAmount.lt(BIG_NUMBER_ONE)) {
            totalBorrowedAmount = BIG_NUMBER_ZERO
        } else {
            totalBorrowedAmount = totalBorrowedAmount.div(10**decimals)
        }
        const totalLiquidityAmount = new BigNumber(eX(info.liquidity.availableAmount.toString(), -decimals)).plus(totalBorrowedAmount).minus(eX(info.liquidity.ownerUnclaimed.toString(), -18 - decimals))
        info.liquidity.liquidityPrice = eX(info.liquidity.marketPrice.toString() || "0", -18)
        const mintTotalSupply = eX(info.collateral.mintTotalSupply.toString(), -1 * Number(decimals))
        if (mintTotalSupply.isZero() || totalLiquidityAmount.isZero()) {
            info.liquidity.exchangeRate = BIG_NUMBER_ONE
        } else {
            info.liquidity.exchangeRate = mintTotalSupply.div(totalLiquidityAmount)
        }
    })
}
async function getAllLendingReserveAndMarketPrice(connection:Connection,reserveArrayInner:Array<Detail<Reserve>>){
    const res = await Promise.all(
        [
            connection.getMultipleAccountsInfo(ALL_IDS.slice(0,100)),
            connection.getMultipleAccountsInfo(ALL_IDS.slice(100,ALL_IDS.length))
        ]
    )
    const accounts = res[0].concat(res[1])
    const reserveAccounts = accounts.slice(0,RESERVE_IDS.length)
    const marketPriceAccounts = accounts.slice(RESERVE_IDS.length,RESERVE_IDS.length * 2)
    const lpReserves = accounts.slice(RESERVE_IDS.length * 2, ALL_IDS.length)
    for (let i=0;i<reserveAccounts.length;i++){
        const reserveAccountInfo = reserveAccounts[i]
        const marketPriceAccountInfo = marketPriceAccounts[i]
        if (reserveAccountInfo!==null && marketPriceAccountInfo!==null){
            const reserve = ReserveParser(RESERVE_IDS[i],reserveAccountInfo)
            reserve.info.liquidity.name = RESERVE_NAMES[i]
            reserve.info.liquidity.poolType = LENDING_ARRAY.find((item)=> item.lendingID.equals(reserve.info.lendingMarket))!.lendingName
            // if (firstQuery){
            //     console.log(RESERVE_NAMES[i]+":",eX( reserve.info.liquidity.marketPrice.toString(),-18).toFixed(4))
            // }
            const marketPrice = PriceParser(RESERVE_LARIX_ORACLES[i],marketPriceAccountInfo)
            // reserve.info.liquidity.marketPrice = marketPrice.info.price.mul(
            //     new BN(10)
            //         .pow(
            //             new BN(18-marketPrice.info.expo)
            //         )
            // )
            if (firstQuery){
                console.log(RESERVE_NAMES[i]+":",eX( reserve.info.liquidity.marketPrice.toString(),-18).toFixed(4))
            }
            reserveArrayInner.push(reserve)
        }
    }
    for (let i=0;i<LP_RESERVE_IDS.length;i+=1){
        const value = 10
        const reserve = ReserveParser(LP_RESERVE_IDS[i].reserveID,lpReserves[i*value])
        reserve.info.liquidity.name = LP_RESERVE_IDS[i].name
        const amm = AmmParser(LP_RESERVE_IDS[i].ammID,lpReserves[i*value+1])
        const lpMint = MintParser(LP_RESERVE_IDS[i].lpMint,lpReserves[i*value+2])
        const coinMintPrice = PriceParser(LP_RESERVE_IDS[i].coinMintPrice,lpReserves[i*value+3])
        const pcMintPrice = PriceParser(LP_RESERVE_IDS[i].pcMintPrice,lpReserves[i*value+4])
        const ammOpenOrders = AmmOpenOrdersLayoutParser(amm.info.ammOpenOrders,lpReserves[i*value+5])
        const ammCoinMint = TokenAccountParser(LP_RESERVE_IDS[i].ammCoinMintSupply,lpReserves[i*value+6])
        const ammPcMint = TokenAccountParser(LP_RESERVE_IDS[i].ammPcMintSupply,lpReserves[i*value+7])
        const coinTotalAmount = eX(
            ammCoinMint.info.amount.add(ammOpenOrders.info.baseTokenTotal??new BN(0)).sub(amm.info.needTakePnlCoin).toString(),
            -amm.info.coinDecimals.toNumber()
        )
        const pcTotalAmount = eX(
            ammPcMint.info.amount.add(ammOpenOrders.info.quoteTokenTotal??new BN(0)).sub(amm.info.needTakePnlPc).toString(),
            -amm.info.pcDecimals.toNumber()
        )
        const coinSymbol = reserve.info.liquidity.name.split('-')[0]
        const pcSymbol = reserve.info.liquidity.name.split('-')[1]
        const coinPrice = eX(coinMintPrice.info.price.toString(),-coinMintPrice.info.expo)
        const pcPrice = eX(pcMintPrice.info.price.toString(),-pcMintPrice.info.expo)
        const lpTotalSupplyAmount = eX(lpMint.info.supply.toString(),-lpMint.info.decimals)
        // console.log(reserve.info.liquidity.name,"lpTotalSupplyAmount",lpTotalSupplyAmount.toString());
        const lpPrice = (2 * Math.sqrt(coinTotalAmount.times(coinPrice).toNumber())*Math.sqrt(pcTotalAmount.times(pcPrice).toNumber()))/lpTotalSupplyAmount.toNumber()

        // if (firstQuery){
        //     console.log(reserve.info.liquidity.name+":",eX(reserve.info.liquidity.marketPrice.toString(),-18).toFixed(4))
        // }
        // reserve.info.liquidity.marketPrice = eX(lpPrice,18)
        reserve.info.liquidity.poolType = LENDING_ARRAY.find((item)=> item.lendingID.equals(reserve.info.lendingMarket))!.lendingName
        if (firstQuery){
            console.log(reserve.info.liquidity.name+":",eX(reserve.info.liquidity.marketPrice.toString(),-18).toFixed(4))
        }
        reserveArrayInner.push(reserve)
    }
    return 0
}
async function getSlot(connection:Connection){
    return await connection.getSlot("finalized")
}
async function getSlots(connection:Connection){
    const clockAccountInfo = await connection.getAccountInfo(SYSVAR_CLOCK_PUBKEY)
}
function refreshIndex(allReserve:Detail<Reserve>[],currentSlot:BN){
    allReserve.map((reserve)=>{
        const slotDiff = currentSlot.sub(reserve.info.lastUpdate.slot)
        const {lTokenMiningRatio,borrowMiningRatio} = getMineRatio(reserve.info)
        const slotDiffTotalMining = new BigNumber(reserve.info.bonus.totalMiningSpeed.toString()).times(new BigNumber(slotDiff.toString()))
        if (!lTokenMiningRatio.eq(0)){
            if (reserve.info.collateral.mintTotalSupply.cmp(ZERO)!==0){
                const plus = slotDiffTotalMining.times(lTokenMiningRatio).div(new BigNumber(reserve.info.collateral.mintTotalSupply.toString())).times(BIG_NUMBER_WAD)
                const newIndex = new BigNumber(reserve.info.bonus.lTokenMiningIndex.toString()).plus(
                    plus
                ).toString()

                // console.log('refreshIndex newIndex = ',newIndex.toString())
                reserve.info.bonus.lTokenMiningIndex = new BN(newIndex.split(".")[0])
            }

        }
        if (!borrowMiningRatio.eq(0)){
            if (reserve.info.liquidity.borrowedAmountWads.cmp(ZERO)!==0){
                const newIndex = new BigNumber(reserve.info.bonus.borrowMiningIndex.toString()).plus(
                    slotDiffTotalMining.times(borrowMiningRatio).div(new BigNumber(reserve.info.liquidity.borrowedAmountWads.toString()).div(BIG_NUMBER_WAD)).times(BIG_NUMBER_WAD)
                )
                reserve.info.bonus.borrowMiningIndex = new BN(newIndex.toFixed(0))
            }
        }
    })
}
function accrueInterest(allReserve:Detail<Reserve>[],currentSlot:BN){

}
