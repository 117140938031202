import {Detail, LendingMarket, refreshReserveInstruction, Reserve} from "@/api/models";
import {WalletAdapter} from "@/api/wallets";
import {Account, PublicKey, SYSVAR_CLOCK_PUBKEY, TransactionInstruction} from "@solana/web3.js";
import {
    CONFIG_INSTRUCTION,
    CONFIG_MARKET_INSTRUCTION,
    CONFIG_RESERVE_INSTRUCTION,
    CONFIG_VALUE_TYPE
} from "@/api/config/constent";
import BufferLayout from "buffer-layout";
import BN from "bn.js";
import * as Layout from './../utils/layout';
import {getConnection, sendTransaction} from "@/api/context/connection";
import {refreshReserves} from "@/api/actions/utils/refreshReserves";
import {claimReserveOwnerFee} from "@/api/actions/setLendingMarketConfig";
async function claimOwnerFee(lendingMarket: Detail<LendingMarket>, wallet: WalletAdapter, value:any,reserve:Detail<Reserve>,lendingProgramId:PublicKey){
    if (wallet.publicKey==null || wallet.publicKey===undefined){
        return
    }
    let receive
    if(value){
        receive = new PublicKey(value)
    } else {
        receive = wallet.publicKey
    }
    const instructions:TransactionInstruction[] = []
    const cleanupInstructions: TransactionInstruction[] = []
    const signers: Account[]= []
    await claimReserveOwnerFee(instructions,cleanupInstructions,signers,lendingMarket,wallet,reserve,receive,lendingProgramId)
    console.log(instructions[1].keys[0].pubkey.toString());
    return await sendTransaction(
        await getConnection(),
        wallet,
        instructions.concat(cleanupInstructions),
        [],
        true
    )
}
export async function setReserveConfig(
    lendingMarket:Detail<LendingMarket>,
    reserve:Detail<Reserve>,
    configType:any,
    value:any,
    wallet:WalletAdapter,
    lendingProgramId:PublicKey
    ){
    if (wallet.publicKey == null){
        throw new Error("wallet need connection")
    }
    if (configType.key === "ClaimOwnerFee"){
        return claimOwnerFee(lendingMarket,wallet,value,reserve,lendingProgramId)
    }
    console.log('setReserveConfig')
    console.log('lendingMarket',lendingMarket.pubkey.toString())
    console.log('lendingProgramId',lendingProgramId.toString())
    const keys = [
        {
            pubkey: lendingMarket.pubkey,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: wallet.publicKey,
            isSigner: true,
            isWritable: false,
        },
        {
            pubkey: reserve.pubkey,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: SYSVAR_CLOCK_PUBKEY,
            isSigner: false,
            isWritable: false,
        },
    ]
    if (configType.valueType == CONFIG_VALUE_TYPE.PUBLICKEY){
        keys.push( {
            pubkey: value,
            isSigner: false,
            isWritable: false,
        })
    }
    console.log("configType=",configType)
    console.log("configType.valueType=",configType.valueType)
    console.log("value=",value)
    let data;
    if (configType.valueType == CONFIG_VALUE_TYPE.PUBLICKEY){
        const dataLayout = BufferLayout.struct([
            BufferLayout.u8("instruction"),
            BufferLayout.u8("config_type_instruction"),
            BufferLayout.u8("reserve_type_instruction"),
        ]);
        data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                instruction: CONFIG_INSTRUCTION,
                config_type_instruction:CONFIG_RESERVE_INSTRUCTION,
                reserve_type_instruction:configType.instruction,
            },
            data,
        );
    } else if(configType.valueType == CONFIG_VALUE_TYPE.UINT8){
        const dataLayout = BufferLayout.struct([
            BufferLayout.u8("instruction"),
            BufferLayout.u8("config_type_instruction"),
            BufferLayout.u8("reserve_type_instruction"),
            BufferLayout.u8("new_value"),
        ]);
        data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                instruction: CONFIG_INSTRUCTION,
                config_type_instruction:CONFIG_RESERVE_INSTRUCTION,
                reserve_type_instruction:configType.instruction,
                new_value:new BN(value)
            },
            data,
        );
    } else if (configType.valueType == CONFIG_VALUE_TYPE.UINT64){
        const dataLayout = BufferLayout.struct([
            BufferLayout.u8("instruction"),
            BufferLayout.u8("config_type_instruction"),
            BufferLayout.u8("reserve_type_instruction"),
            Layout.uint64("new_value"),
        ]);
        data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                instruction: CONFIG_INSTRUCTION,
                config_type_instruction:CONFIG_RESERVE_INSTRUCTION,
                reserve_type_instruction:configType.instruction,
                new_value:new BN(value)
            },
            data,
        );
        console.log("data=",data)
    } else if (configType.valueType == CONFIG_VALUE_TYPE.BOOL){
        const dataLayout = BufferLayout.struct([
            BufferLayout.u8("instruction"),
            BufferLayout.u8("config_type_instruction"),
            BufferLayout.u8("reserve_type_instruction"),
            BufferLayout.u8("new_value"),
        ]);
        data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                instruction: CONFIG_INSTRUCTION,
                config_type_instruction:CONFIG_RESERVE_INSTRUCTION,
                reserve_type_instruction:configType.instruction,
                new_value:new BN(value)
            },
            data,
        );
    } else {
        throw new Error("未定义的配置类型")
    }
    const transactions:TransactionInstruction[] = []

    await refreshReserves(transactions,[reserve],lendingProgramId )
    transactions.push( {
        keys,
        programId:lendingProgramId,
        data
    })
    return await sendTransaction(
        await getConnection(),
        wallet,
        transactions,
        [],
        true
    )
}