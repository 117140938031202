import {Account, Connection, PublicKey, TransactionInstruction} from "@solana/web3.js";
import {
    Detail,
    Obligation,
    refreshObligationInstruction,
    Reserve
} from "@/api/models";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";

export function refreshObligation(
    connection:Connection,
    signers: Account[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    obligation: Detail<Obligation>,
    allReserve:Array<Detail<Reserve>>,
    lendingProgramId:PublicKey
){
    obligation.info.deposits.map((collateral)=>{
        allReserve.map((reserve)=>{
            if (collateral.depositReserve.equals(reserve.pubkey)){
                 refreshReserve(
                    connection,
                    signers,
                    instructions,
                    cleanupInstructions,
                    reserve,
                     lendingProgramId
                 )
            }
        })
    })
    obligation.info.borrows.map((collateral)=>{
        allReserve.map((reserve)=>{
            if (collateral.borrowReserve.equals(reserve.pubkey)){
                refreshReserve(
                    connection,
                    signers,
                    instructions,
                    cleanupInstructions,
                    reserve,
                    lendingProgramId
                )
            }
        })
    })
    instructions.push(refreshObligationInstruction(
        obligation.pubkey,
        obligation.info.deposits.map(collateral => collateral.depositReserve),
        obligation.info.borrows.map(liquidity => liquidity.borrowReserve),
    ))
}