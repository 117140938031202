import {Detail, Reserve, ReserveParser} from "@/api/models";
import {Oracle, OracleParser} from "@/api/models/state/oracle";
import {getConnection, getDevConnection} from "@/api/context/connection";
import {ISOLATION_ORACLE_ID, ORACLE_ID} from "@/api/utils/ids";
import {PublicKey} from "@solana/web3.js";

export async function getOracle(): Promise<Detail<Oracle>>{
    return await _getOracle(ORACLE_ID)
}
export async function getIsolationOracle():Promise<Detail<Oracle>>{
    return await _getOracle(ISOLATION_ORACLE_ID)
}

async function _getOracle(oracleId:PublicKey):Promise<Detail<Oracle>>{
    const connection = await getDevConnection()
    const oracleAccountInfo = await connection.getAccountInfo(oracleId)
    if (oracleAccountInfo==null){
        throw new Error("reserveAccountInfo is null")
    }
    return OracleParser(oracleId,oracleAccountInfo)
}