import {Account, Connection, PublicKey, TransactionInstruction} from "@solana/web3.js";
import {createClaimObligationMineInstruction} from "@/api/models/instructions/claimObligationMine";
import {Detail, Reserve} from "@/api/models";

import {Obligation} from "../../models";
import {refreshObligation} from "@/api/actions/utils/refreshObligation";


export async function claimObligationMine(
    connection:Connection,
    signers: Account[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    obligation:Detail<Obligation>,
    miningSupply:PublicKey,
    larixAssociatedTokenAccount:PublicKey,
    wallet:any,
    lendingMarket:PublicKey,
    lendingMarketAuthority:PublicKey,
    allReserve:Detail<Reserve>[],
    lendingProgramId:PublicKey

){
    if (obligation==undefined){
        return
    }
    refreshObligation(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        obligation,
        allReserve,
        lendingProgramId
    )
    instructions.push(createClaimObligationMineInstruction(
        obligation,
        miningSupply,
        larixAssociatedTokenAccount,
        wallet.publicKey,
        lendingMarket,
        lendingMarketAuthority
    ))
}