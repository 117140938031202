import store from '../store'
export default function txFailed( dialogSwitchMutation?: string) {
    store.commit('updateErrorModel', true)
    store.commit('updateConfirmDialogTip', 'pendingDialog.errorTips')
    if (store.state.wallet.walletAddress){
        setTimeout(() => {
            if (dialogSwitchMutation) store.commit(dialogSwitchMutation, true)
            store.commit('updateConfirmDialog', false)
            store.commit('updateErrorModel', false)
        }, 3000)
    }else {
        store.commit('updateErrorModel', false)
        store.commit('updateCheckModel', false)
    }
}