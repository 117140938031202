import {
    Detail, Obligation,
    Reserve,
    TokenAccount
} from "@/api/models";
import {Account, PublicKey, TransactionInstruction} from "@solana/web3.js";

import {redeemReserveCollateral} from "@/api/actions/utils/redeemReserveCollateral";
import BN from "bn.js"
import {getObligation} from "@/api/provider/obligationProvider";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {Mining} from "@/api/models/state/mining";
import {withdrawMining} from "@/api/actions/utils/withdrawMining";
import {U64_MAX} from "@/api/constants/utils";
import {withdrawObligationCollateral} from "@/api/actions/utils/withdrawObligationCollateral";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";
import {createAssociatedTokenAccount, findOrCreateAccountByMint} from "@/api/actions/utils/account";
import {getMining} from "@/api/provider/miningProvider";
export async function redeemReserve(
    source: TokenAccount,
    collateralAmount: BN,
    wallet: any,
    detailReserve:Detail<Reserve>,
    destinationLiquidity:TokenAccount,
    allReserve:Array<Detail<Reserve>>,
    mining:Detail<Mining>,
    lendingProgramId:PublicKey,
    obligation?:Detail<Obligation>,
){
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];
    const connection = await getConnection()
    let sourceAddress;
    if (source == undefined){
        sourceAddress = await createAssociatedTokenAccount(
            connection,
            instructions,
            wallet.publicKey,
            detailReserve.info.collateral.mintPubkey,
            wallet.publicKey,
        )
    } else {
        sourceAddress = source.pubkey
    }
    if (mining===undefined){
        const myMinings = await getMining(wallet.publicKey,'userAction')
        if (myMinings.length>0){
            mining = myMinings[0]
        }
    }
    if (obligation===undefined){
        const myObligations = await getObligation(wallet.publicKey,'userAction')
        if (myObligations.length>0){
            obligation = myObligations[0]
        }
    }

    let hasObligation:boolean = false
    if (obligation != undefined){
        obligation.info.deposits.map((collateral)=>{
            if (collateral.depositReserve.equals(detailReserve.pubkey)){
                hasObligation = true
            }
        })
    }
    if (hasObligation){
        if (obligation!=undefined){
            await withdrawObligationCollateral(
                connection,
                signers,
                instructions,
                cleanupInstructions,
                obligation,
                collateralAmount.cmp(new BN(-1)) == 0?U64_MAX:collateralAmount,
                sourceAddress,
                detailReserve,
                wallet,
                allReserve,
                lendingProgramId
            )
        }
    } else {
        await refreshReserve(
            connection,
            signers,
            instructions,
            cleanupInstructions,
            detailReserve,
            lendingProgramId
        )
        await withdrawMining(
            connection,
            signers,
            instructions,
            cleanupInstructions,
            mining.pubkey,
            sourceAddress,
            detailReserve,
            collateralAmount.cmp(new BN(-1)) == 0?U64_MAX:collateralAmount,
            wallet,
            detailReserve.info.lendingMarket
        )
    }
    const destinationLiquidityPubkey = await findOrCreateAccountByMint(
        wallet.publicKey,
        wallet.publicKey,
        instructions,
        cleanupInstructions,
        detailReserve.info.liquidity.mintPubkey,
        signers,
        destinationLiquidity
    )
    await redeemReserveCollateral(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        sourceAddress,
        collateralAmount.cmp(new BN(-1)) == 0?U64_MAX:collateralAmount,
        wallet,
        detailReserve,
        destinationLiquidityPubkey,
        lendingProgramId
    )
    return  await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    );

}

