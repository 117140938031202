

import {useI18n} from 'vue-i18n'
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import errorType from '../constant/errorType'


export default defineComponent ({
    name: "TransactionDialog",
    date (){
        return {
         
        }
    },
    computed:{
     transactionDialogVisible: {
       get ():any {
         return this.$store.state.dialog.transactionDialogVisible
      },
       set (value:any) {
         this.$store.commit('updateTransactionDialogVisible', value)
      }
     },
     ...mapState({
      errorModel: (state: any) => state.txDialog.errorModel,
      checkModel: (state: any) => state.txDialog.checkModel,
      confirmDialog: (state: any) => state.txDialog.confirmDialog,
      confirmDialogTip: (state: any) => state.txDialog.confirmDialogTip,
       errorContext: (state: any) => state.txDialog.errorContext,
     })
    },
    methods:{
      getErrorContext(){
        const languageIndex = this.$i18n.locale==='zh-cn'?1:0
        let type = this.errorContext
        let context = new errorType().getErrorType(type,languageIndex)
        return context
      }
    },
    setup() {
      const {t} = useI18n()
      return {
        t,
      }
    },
})
