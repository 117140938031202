class errorType{
    public errorContext = {
        0:{
            type11: "Input amount is invalid",
            type20:"Insufficient liquidity available",
            type23:"Withdraw amount too small",
            type24:"Withdraw amount too large",
            type25:"Borrow amount too small to receive liquidity after fees",
            type26:"Borrow amount too large for collateral amount",
            type27:"Repay amount too small to transfer liquidity",
            type28:"Liquidation amount too small to receive collateral",
            type31:"Obligation reserve limit exceeded",
            type32:"Obligation owner is invalid",
            type33:"Obligation deposits are empty",
            type34:"Obligation borrows are empty",
            type37:"Invalid obligation collateral",
            type38:"Invalid obligation liquidity",
            type39:"Obligation collateral is empty",
            type40:"Obligation liquidity is empty",
            type56:"Deposit amount too large for account balance",
            type57:"Redeem amount too large for deposit balance",
            type58:"Withdraw amount too large for deposit balance",
            type59:"Repay amount too large for borrow amount",
            type60:"Deposit is now paused",
            type61:"Borrow is now paused",
            type62:"Liquidation is now paused",
            type63:"Can not withdraw all deposit",
            type1000:"Each account is limited to 5 collateral and borrowing interactions in total, simultaneously.",
        },
        1: {
            type11:"輸入金額無效",
            type20:"可用流動性不足",
            type23:"取款金額太小",
            type24:"取款金額太大",
            type25:"借款金額太小",
            type26:"質押物價值不足",
            type27:"還款金額太小不足以完成轉賬",
            type28:"清算金額不足以收到抵押物",
            type31:"超過借款額度",
            type32:"只有倉位所有人才可以操作倉位",
            type33:"沒有開啓任何抵押",
            type34:"沒有借款",
            type37:"質押物無效",
            type38:"債務流動性無效",
            type39:"沒有開啓任何抵押",
            type40:"無債務流動性",
            type56:"錢包餘額不足",
            type57:"提款金額超過存款餘額",
            type58:"提款金額超過存款餘額",
            type59:"還款金額大於借款餘額",
            type60:"存款已暫停",
            type61:"借款已暫停",
            type62:"清算已暫停",
            type63:"無法提取所有存款",
            type1000:"抵押和借款币种总数最多为5",
        }
    }
    getErrorType(type:number,languageIndex:number){
        switch (type){
            case 11:
                // @ts-ignore
                return this.errorContext[languageIndex].type11
            case 20 :
                // @ts-ignore
                return this.errorContext[languageIndex].type20
            case 23 :
                // @ts-ignore
                return this.errorContext[languageIndex].type23
            case 24 :
                // @ts-ignore
                return this.errorContext[languageIndex].type24
            case 25:
                // @ts-ignore
                return this.errorContext[languageIndex].type25
            case 26:
                // @ts-ignore
                return this.errorContext[languageIndex].type26
            case 27:
                // @ts-ignore
                return this.errorContext[languageIndex].type27
            case 28:
                // @ts-ignore
                return this.errorContext[languageIndex].type28
            case 31:
                // @ts-ignore
                return this.errorContext[languageIndex].type31
            case 32:
                // @ts-ignore
                return this.errorContext[languageIndex].type32
            case 33:
                // @ts-ignore
                return this.errorContext[languageIndex].type33
            case 34:
                // @ts-ignore
                return this.errorContext[languageIndex].type34
            case 37:
                // @ts-ignore
                return this.errorContext[languageIndex].type37
            case 38:
                // @ts-ignore
                return this.errorContext[languageIndex].type38
            case 39:
                // @ts-ignore
                return this.errorContext[languageIndex].type39
            case 40:
                // @ts-ignore
                return this.errorContext[languageIndex].type40
            case 56:
                // @ts-ignore
                return this.errorContext[languageIndex].type56
            case 57:
                // @ts-ignore
                return this.errorContext[languageIndex].type57
            case 58:
                // @ts-ignore
                return this.errorContext[languageIndex].type58
            case 59:
                // @ts-ignore
                return this.errorContext[languageIndex].type59
            case 60:
                // @ts-ignore
                return this.errorContext[languageIndex].type60
            case 61:
                // @ts-ignore
                return this.errorContext[languageIndex].type61
            case 62:
                // @ts-ignore
                return this.errorContext[languageIndex].type62
            case 63:
                // @ts-ignore
                return this.errorContext[languageIndex].type63
            case 1000:
                // @ts-ignore
                return this.errorContext[languageIndex].type1000

        }
    }
}
export default errorType