// src/locales/zh-cn.ts
export default {
    name: '中文',
    headers:{
        dashBoard:'首頁',
        market:'市場',
        liquidation:'清算',
        larix:'LARIX',
        stake:'開倉',
        About:'關於',
        dao:'DAO',
        connect:'連接錢包',
        reward:'領取獎勵',
    },
    reward:{
        larixBalance:'Larix餘額',
    },
    wallet:{
        reward:'領取獎勵',
        connect:'連接錢包',
        faucet:'测试币水龙头',
        connectToAWallet:'連接錢包',
        yourWallet:'你的錢包地址',
        disconnect:'斷開連接',
        connected:'已連接',
        harvest:'領取',
        walletBalance: '錢包餘額',
        unclaimedBalance: '未領取餘額',
        price: '價格',
        nothingToClaim: '沒有可領取的',
        subAccount:'子賬戶',
    },
    footer:{
        doc:'文檔'
    },
    home:{
      totalSupply:'縂存款',
      totalBorrow:'縂借款',
      totalMined:'縂流通量',
      larixPrice:'Larix價格',
      supplyMarket:'存款市場',
      borrowMarket:'借款市場',
      myBill:'我的賬單',
      asset:'資產',
      collateral:'質押',
      allMarket:'所有市場',
      supplyAPY:'存款收益',
      wallet:'錢包',
        borrowAPY:'借款成本',
        liquidity:'流動性',
        operation:'操作',
        borrowBalance:'借款餘額',
        supplyBalance:'存款餘額',
        withdraw:"提現",
        repay:"還款",
        supply:"存款",
        borrow:"借款",
        loading:"正在加載數據",
        connectToAWallet:'連接錢包',

    },
    supplyAndBorrow:{
        borrowLimit:"借款限額",
        netAPY:"净收益率",
        borrowBalance:'借款餘額',
        supplyBalance:'存款餘額',

    },
    supplyDialog: {
        supply: "存款",
        withDraw: "提現",
        wallet: "錢包",
        balance: "餘額",
        walletBalance: "錢包餘額",
        liquidity: "流動性",
        max: "最大值",
        limit85: "85%額度限制",
        supplyApy: "存款收益",
        supplyBalance: "存款餘額",
        borrowLimit: "借入限額",
        supplyRate: "存款比例",
        withdrawRate: "取款比例",
        invalidNumber:"輸入無效",
        insufficientLiquidity:"流動性不足",
        stillWithdraw:"仍要提現",
        borrowLimitUsedPercent:"額度占用",
        collateralFactor:"質押因子",
        insufficientFunds:"餘額不足",
        cancel:'取消',
        interestRate: "利率",
        miningAPY: "挖礦收益",
        supplyMaxLimit100M:"最大縂存款限額為一億美金.",
    },
    borrowDialog: {
        borrow: "借款",
        repay: "還款",
        wallet: "錢包",
        balance: "余餘額",
        walletBalance: "錢包餘額",
        liquidity: "剩餘可借",
        borrowMax: "最大值",
        Max:'最大值',
        borrowApy: "成本",
        borrowBalance: "借款額",
        borrowLimit: "借入限額",
        borrowRate: "借款比例",
        repayRate: "还款比例",
        borrowMaxInTokenUnit:"最高限額",
        borrowLimitUsedPercent:"額度占用",
        collateralFactor:"質押因子",
        invalidNumber:"輸入無效",
        insufficientLiquidity:"流動性不足",
        stillBorrow:"仍要借款",
        noBalanceToRepay:"沒有借款可償還",
        insufficientFunds:"餘額不足",
        cancel:'取消',
        interestRate: "利率",
        miningAPY: "挖礦收益",
        reachPositionLimit:"抵押和借款币种总数最多为5",
    },
    market:{
        supply:"存款",
        borrow:"借款",
        top3Markets:"排行前三",
        asset:"資產",
        totalSupply:"縂存款",
        supplyAPY:"存款利率",
        totalBorrow:"縂借款",
        borrowAPY:"借款利率",
        details:"查看詳情",
        supplyBorrow:'存借總額',
        loading:"正在加載數據",
        connectToAWallet:'連接錢包',
        totalSupplyApy:"縂存款/利率",
        totalBorrowApy:"縂借款/利率",
        depositLimit:"存款限額",
        supplyIR:'存款利率',
        borrowIR:'借款利率',
        supplyDistributionAPR:'存款挖礦 APR',
        borrowDistributionAPR:'借款挖礦 APR',
        supplyIRHelpContext:'存款APY = 存款利率 + 存款挖礦 APY',
        borrowIRHelpContext:'借款APY = 借款利率 - 借款挖礦 APY',
    },
    marketId:{
        market:"市場",
        supply:"存款",
        borrow:"借款",
        netRate:"净利率",
        supplyAPY:"存款收益",
        distributionSupplyAPY:"存款挖礦APY",
        distributionBorrowAPY:"借款挖礦APY",
        totalSupply:"縂存款",
        borrowAPY:"借款成本",
        totalBorrow:"縂借款",
        interestRateModel:"利率模型",
        marketDetails:"市場詳情",
        Price:"價格",
        marketLiquidity:"市場流動性",
        collateralFactor:"質押因子",
        exchangeRate:"匯率",
        supplyMiningAPR:"存款挖礦年利率",
        borrowMiningAPR:"借款挖礦年利率",
        utilization:"使用率",
        supplyChartAPY:"存款利率",
        borrowChartAPY:"借款利率",
        mndeAPR:"MNDE 年利率"
    },
    larix:{
        amountinCirculation:"流通量",
        circulatingMarketCap:"流通市值",
        totalSupply:"代幣總量",
        lARIXDistribution:"LARIX分配",
        market:"市場",
        perDay:"挖礦日產出",
        supplyMiningAPR:"存款挖礦年利率",
        borrowMiningAPR:"借款挖礦年利率",
        dailyDistribution:"每日分配",
        other:"其他",
        distribution:"分配"
    },
    About:{
        aboutLarix:"關於 Larix",
        aboutLarixContext:"Larix是Solana公鏈的終極借貸門戶，采用動態利率模型，創建資本高效的風險管理資金池，使得資產以安全的方式被充分使用。 此外，基於代幣經濟獎勵制度， Larix能夠持續激勵分配，調節實際借貸需求。 一切資產價值標的，如加密貨幣、穩定幣、合成資產、 NFTs和其他資產類型均將被納入資產抵押系列。",
        auditReport:"審計報告",
        whyLarix:"爲什麽選擇Larix",
        reason1:"第一個上綫挖礦的借貸協議",
        reason2:"第一個將會支持P2P NFT借貸的協議",
        reason3:"第一個部分開源的借貸協議",
        roadmap:"路徑圖",
        roadmapStep1:"第一階段: 1月-9月 2021",
        roadmapStep2:"第二階段: 2021年底-2022年",
        roadmapStep3:"第三階段：2022年以后",
        roadmapStep1Context:"Larix 將從傳統的超質押借貸模式開始，滿足Solana生態用戶的基礎借貸需求。 在這個階段, Larix將接受數字貨幣, 穩定幣,以及合成資產作爲抵押物.  Larix 在兼顧安全性和資金使用效率中找到平衡，增强了系統風險管理能力，降低了協議級別的潛在損失。",
        roadmapStep2Context:"与Pyth预言机合作，将高保真的 (HiFi)金融市场与去中心化金融 (DeFi) 世界连接起来。 该合作伙伴关系使 Solana 的参与者能够在可靠、灵敏、及时的市场数据支持下， 在 Larix 平台通过抵押如，股票、债券和更传统的基础资产等合成资产，获得 DeFi 类流流动性的加密资产标的。",
        roadmapStep3Context:"Larix 协议将扩大抵押物范围，接受非同质化代币 (NFT) 作为抵押，并允许所有抵押资产类别的点对点借贷： 数字钱包中的所有价值资产标的，都将以合理的价值作为释放流动性的抵押物。 Larix 将满足通过质押 NFT 资产来获得流动性和赚取收益的用户，及更多激进策略的需求。",
        seedRoundInvestor:'投資人',
        partnership:"生態夥伴",
        audit:"審計",
    },
    liquidation: {
        liquidation : '清算',
        account: '賬戶',
        amountTip: '輸入您想還多少',
        assetToRepay :'償還的資產',
        assetsToRepay :'償還的資產',
        maxRepayAmount: '最高償還金額',
        assetToSeize : '獲取的資產',
        assetsToSeize : '獲取的資產',
        maxSeizeAmount : '最大可獲取金額',
        debtRatio : '負債率',
        action : '操作',
        liquidate : '清算',
    },
    noticeDialog: {
        notice : '通知',
        text : '挖礦開啟倒數計時',
        confirm: '我已知曉'
    },
    pendingDialog: {
        transactionPending: "等待交易",
        viewOnEtherscan: "查看交易",
        confirm: "交易確認中...",
        broadcast: "交易廣播中...",
        waitingEvm: "等待EVM結果...",
        errorTips:"交易失敗",
        checkTips:"交易成功"
      }
  }
  