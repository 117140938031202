
import {
    Account, PublicKey,
    TransactionInstruction,
} from '@solana/web3.js';
import {
    Detail,
    Obligation,
    repayObligationLiquidityInstruction,
    Reserve,
    TokenAccount,
} from '../models';
import {ensureSplAccount} from "./utils/account"
import {getConnection, sendTransaction} from "../context/connection";
import {WalletAdapter} from "@/api/wallets";
import BN from "bn.js";
import {U64_MAX} from "@/api/constants/utils";
import {refreshObligation} from "@/api/actions/utils/refreshObligation";
import {getObligation} from "@/api/provider/obligationProvider";

// @FIXME
export const repay = async (
    obligation: Detail<Obligation>,
    repayReserve: Detail<Reserve>,
    source: TokenAccount,
    liquidityAmount: number|BN,
    wallet: WalletAdapter,
    allReserve:Array<Detail<Reserve>>,
    lendingProgramId:PublicKey
) => {

    if (wallet.publicKey == null){
        throw new Error("wallet need connection")
    }
    if (obligation===undefined){
        const myObligations = await getObligation(repayReserve.info.lendingMarket,'userAction')
        if (myObligations.length>0){
            obligation = myObligations[0]
        }
    }
  // user from account
    const connection = await getConnection()
  const signers: Account[] = [];
  const instructions: TransactionInstruction[] = [];
  const cleanupInstructions: TransactionInstruction[] = [];

    if (liquidityAmount === -1){
        liquidityAmount = U64_MAX
    }
  const sourceLiquidity = ensureSplAccount(
      instructions,
      cleanupInstructions,
      source,
      wallet.publicKey,
      source.info.amount.toNumber()-10000,
      signers
  );
    refreshObligation(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        obligation,
        allReserve,
        lendingProgramId
    )
  instructions.push(
    repayObligationLiquidityInstruction(
      liquidityAmount,
      sourceLiquidity,
      repayReserve.info.liquidity.supplyPubkey,
      repayReserve.pubkey,
      obligation.pubkey,
      repayReserve.info.lendingMarket,
      // lendingMarketAuthority,
      wallet.publicKey,
    ),
  );

  return  await sendTransaction(
      connection,
      wallet,
      instructions.concat(cleanupInstructions),
      signers,
      true,
  );
};
