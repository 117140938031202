
// @ts-nocheck
import {computed, defineComponent, reactive, toRefs} from 'vue'
//@ts-ignore
import SupplyDialog from "../components/SupplyDialog";
//@ts-ignore
import BorrowDialog from "../components/BorrowDialog";
//@ts-ignore
import { useI18n } from 'vue-i18n'
import {mapState} from "vuex";

// import {getLendingMarket} from "@/api/provider/lendingMarketProvider";
// import {getLendingReserve} from "@/api/provider/lendingReserveProvider";
// import {getTokenAccounts} from "@/api/provider";
// import {getMintInfo,} from "@/api/provider";
// import {getObligation} from "@/api/provider";


import {depositReserve} from "@/api/actions/depositReserve";
// eslint-disable-next-line no-unused-vars
import BN from 'bn.js';
import {intoObligation} from "@/api/actions/intoObligation"
import {eX, formatDollars, formatDollarsKmb, getBigNumberStr, formatBigNumberStr} from "@/utils/helpers";
import {redeemReserve} from "@/api/actions/redeemReserve"
import {borrow} from "@/api/actions/borrow";
import {repay} from "@/api/actions/repay";
import {exitObligation} from "@/api/actions/exitObligation";
import  {useStore} from "@/store";
import {AppActionTypes} from "@/store/modules/app/action-types";
import {getLarixLockPool} from "@/api/context/larixLock"
import {getWallet} from "@/api/context/wallet";
import BigNumber from "bignumber.js";
import appController from "@/controller/appController";
import txSuccess from "@/controller/txSuccess";
import txFailed from "@/controller/txFailed";
import openTxDialog from "@/controller/openTxDialog";
import {
  CONFIG_TYPES,POOL_TYPES
} from "@/api/config/constent"
import {setLendingMarketConfig} from "@/api/actions/setLendingMarketConfig";
import {setReserveConfig} from "@/api/actions/setReserveConfig";
import controlTxDialog from "@/controller/txDialogController";
import {getBridgePool} from "@/api/context/bridgePool"
import {setOracleConfig} from "@/api/actions/setOracleConfig";
import {freezeAccount} from "@/api/actions/freezeAccount";
import {unfreezeAccount} from "@/api/actions/freezeAccount";
import {setRaydiumBridgeConfig} from "@/api/actions/setRaydiumBridgeConfig"
import {PublicKey} from "@solana/web3.js";
import {ORACLE_PROGRAM_ID, ISOLATION_ORACLE_PROGRAM_ID, LARIX_LOCK_POOL_ID} from "@/api/utils/ids";
import {setLarixLockPoolConfig} from "@/api/actions/setLarixLockPoolConfig";
import {Detail, LendingMarket, Reserve} from "@/api/models";
// import {WAD} from "@/api/constants";
// import {getWallet} from "@/api/context/connection";


// import {borrowObligationLiquidity} from "@/api/actions/borrowObligationLiquidity";
// import {repayObligationLiquidity} from "../api/actions/repayObligationLiquidity"
export default defineComponent({
  name: "Home",
  components: {SupplyDialog,
    BorrowDialog},
  data() {
    return {
      //@ts-ignore
      asset: require('../assets/coin/asset_USDT.svg'),
      getBigNumberStr: getBigNumberStr,
      formatDollars: formatDollars,
      formatDollarsKmb: formatDollarsKmb,
      formatBigNumberStr:formatBigNumberStr,

      enableFor: '',
      selectedReserveDetail: {},
      isEnterCollateral:false,
      // supplyDialogVisible:false,
      // borrowDialogVisible:false
      value: 'English',
      languageIndex:0,
      languageValue: {
        label: 'English',
        value: 'en',
        icon: require('../assets/icn-english.svg')
      },
      languageOptions:[
        {
          label: 'English',
          value: 'en',
          icon: require('../assets/icn-english.svg')
        },
        {
          label: "繁體中文",
          value: "zh-cn",
          icon: require('../assets/icn-chinese.svg')
        }
      ],
      currentLanguage: {
        name:'简体中文',
        value: 'zh-cn',
        src: require('../assets/icn-chinese.svg')
      },
      languages: [
        {
          name:'English',
          value: 'en',
          src: require('../assets/icn-english.svg')
        },
        {
          name:'简体中文',
          value: 'zh-cn',
          src: require('../assets/icn-chinese.svg')
        },
      ],
      media: [
        {
          dark: require('../assets/media/twitter_dark.png'),
          light: require('../assets/media/twitter.svg'),
          url: 'https://twitter.com/ProjectLarix',
        },
        {
          dark: require('../assets/media/discord.svg'),
          light: require('../assets/media/discord.svg'),
          url: 'https://discord.gg/hfnRFV9Ngt',
        },
        {
          dark: require('../assets/media/telegram_dark.png'),
          light: require('../assets/media/telegram.svg'),
          url: 'https://t.me/projectlarix',
        },
        {
          dark: require('../assets/media/medium.svg'),
          light: require('../assets/media/medium.svg'),
          url: 'https://medium.com/@ProjectLarix',
        },
        {
          dark: require('../assets/media/github_dark.png'),
          light: require('../assets/media/github.svg'),
          url: 'https://github.com/ProjectLarix',
        },
        // {
        //   dark: require('../assets/media/email.svg'),
        //   light: require('../assets/media/email.svg'),
        //   url: 'https://projectlarix@gmail.com',
        // },
      ],
      emailImg: {
        dark: require('../assets/media/email.svg'),
        light: require('../assets/media/email.svg'),
      },
      emailContent: "projectlarix@gmail.com",
      clientViewPort:0,
      larixLogo:require('../assets/Larix.ico.png'),
      MNDELogo:require('../assets/coin/asset_MNDE.svg'),

      selectedType:'Please choose',
      selectedPool:'Please choose',
      typeOptions:CONFIG_TYPES,
      poolOptions:POOL_TYPES,
      objectTitle:'Choose object',
      objectOptions:[],
      objectDataShow:[],// 展示选中的object 对象的详细信息
      selectedObject:{},

      actionTitle:'Choose action',
      actionOptions:[],
      selectedAction: {},
      actionParameter:'',
      lendingMarket:{} as Detail<LendingMarket>,
      lendingMarketReserves:[] as Detail<Reserve>[]
    }
  },
  setup(){
    const store = useStore()
    const { locale } = useI18n()

    const state = reactive({
      handleSetLanguage: (lang: string) => {
        locale.value = lang
        store.dispatch(AppActionTypes.ACTION_SET_LANGUAGE, lang)
        localStorage.setItem('lang',lang)
        // if(locale.value=='en'){
        //   ElMessage({
        //     message: 'English switch successfully',
        //     type: 'success'
        //   })
        // }else{
        //   ElMessage({
        //     message: '中文切换成功',
        //     type: 'success'
        //   })
        // }
      }
    })
    const language = computed(() => {
      return store.state.app.language
    })
    const { t } = useI18n()
    return {
      ...toRefs(state),
      language,
      t,
    }
  },
  created(){
    window.addEventListener('resize', this.ViewPortAndStyle)
    this.ViewPortAndStyle()
  },
  // beforeUnmount: function () {
  //   window.removeEventListener('resize', this.ViewPortAndStyle)
  // },
  mounted() {
    if (this.$i18n.locale==='zh-cn') this.languageValue = '繁體中文'
  },
  computed: {
    myBillDetails(){
      return this.allReservesDetails.
      filter((item)=> item.supplyBalance.isGreaterThan(0)||item.borrowBalance.isGreaterThan(0)).sort((b,a)=>{
        return a.supplyBalance - b.supplyBalance
      })
    },
    totalMined(){
      return new BigNumber(this.larixCirculation).times(this.larixPrice)
    },
    ...mapState({
      walletAddress: (state: any) => state.wallet.walletAddress,
      // lendingMarket: (state: any) => state.market.lendingMarket,
      allLendingMarket: (state: any) => state.market.allLendingMarket,
      lendingReserveArray: (state: any) => state.market.lendingReserveArray,
      userObligation: (state: any) => state.market.userObligation,
      allReservesDetails: (state: any) => state.market.allReservesDetails,
      isLoadingInfo:(state:any) =>state.market.isLoadingInfo,
      allMarketTotalSupply:(state:any) =>state.market.allMarketTotalSupply,
      allMarketTotalBorrow:(state:any) =>state.market.allMarketTotalBorrow,
      isFullWithdraw:(state:any)=>state.dialog.isFullWithdraw,
      isFullRepay:(state:any)=>state.dialog.isFullRepay,
      mining:(state:any)=>state.market.mining,
      larixCirculation:(state:any)=>state.market.larixCirculation,
      larixPrice:(state:any)=>state.market.larixPrice,
      userTotalSupply: (state: any) => state.market.userTotalSupply,
      userObligationIndex:(state:any)=> state.market.userObligationIndex,
      oracleInfo:(state:any)=> state.market.oracleInfo,
      isolationOracleInfo:(state:any)=>state.market.isolationOracleInfo,
      errorContext:(state:any)=> state.txDialog.errorContext
    })
  },
  methods: {
    chooseType:async function(type:any){
      this.objectOptions = []
      if (type.name==='market') {
        this.objectTitle = ""

      } else if (type.name==="reserve"){
        this.objectTitle = "Choose Reserve"
        this.objectDataShow = null
      } else if (type.name==='oracle'){
        this.objectTitle = ""
        this.objectDataShow = this.formatData({
          "pubkey":this.oracleInfo.pubkey.toString(),
          "owner":this.oracleInfo.info.owner.toString(),
          "pending_owner":this.oracleInfo.info.pendingOwner.toString(),
          "submit_authority":this.oracleInfo.info.submitAuthority.toString()
        })
      } else if(type.name=="isolationOracle"){
        this.objectTitle = ""
        this.objectDataShow = this.formatData({
          "pubkey":this.isolationOracleInfo.pubkey.toString(),
          "owner":this.isolationOracleInfo.info.owner.toString(),
          "pending_owner":this.isolationOracleInfo.info.pendingOwner.toString(),
          "submit_authority":this.isolationOracleInfo.info.submitAuthority.toString()
        })
      }else if (type.name==="raydiumBridge"){
        this.objectTitle = "Choose Bridge Pool"
        for(let reserveDetail of this.allReservesDetails){
          if (reserveDetail.reserveDetail.info.isLP){
            this.objectOptions.push(reserveDetail.symbol)
          }
        }
      } else if (type.name==="larixLockPool"){
        this.objectTitle = ""
        const larixLockPool = await getLarixLockPool()
        this.objectDataShow = this.formatData({
          "pubkey":LARIX_LOCK_POOL_ID.toString(),
          "owner":larixLockPool.owner.toString(),
          "pending_owner":larixLockPool.pendingOwner.toString(),
          "add_larix_authority":larixLockPool.addLarixAuthority.toString()
        })
      } else {
        this.objectTitle = ""
        this.objectDataShow = null
      }
      this.selectedObject = null
      this.selectedAction = null
      this.actionTitle = "Choose action"
      this.actionOptions = type.actions
      this.selectedPool = "Choose pool"
    },
    choosePool(pool:any){
      this.objectOptions = []
      this.lendingMarket = this.allLendingMarket.find((item)=> item.info.lendingType===pool.name)
      if (this.selectedType.name==='market'){
        this.objectDataShow = this.formatData({
          "marketAccount":this.lendingMarket.pubkey.toString(),
          "tokenProgramId":this.lendingMarket.info.tokenProgramId.toString(),
          "pendingOwner":this.lendingMarket.info.pendingOwner.toString(),
          "owner":this.lendingMarket.info.owner.toString(),
          "oracleProgramId":this.lendingMarket.info.oracleProgramId.toString(),
          "larixOracleProgramId":this.lendingMarket.info.larixOracleProgramId.toString(),
          "larixOracleId":this.lendingMarket.info.larixOracleId.toString(),
          "mineSupply":this.lendingMarket.info.mineSupply.toString(),
          "mineLockProgram":this.lendingMarket.info.mineLockProgram.toString(),
          "lockLarixTimesToTime":this.lendingMarket.info.lockLarixTimesToTime.toString(),
          "maxClaimTimes":this.lendingMarket.info.maxClaimTimes.toString()
        })
      }else if (this.selectedType.name==='reserve'){
        this.lendingMarketReserves = this.lendingReserveArray.filter((item)=> item.info.liquidity.poolType===this.lendingMarket.info.lendingType)
        for(let i in this.lendingMarketReserves){
          this.objectOptions.push(this.lendingMarketReserves[i].info.liquidity.name)
        }
      }
    },
    chooseObject:async function(index:number){
      if (this.selectedType.name==='reserve'){
        this.selectedObject = this.lendingMarketReserves[index]
        console.log(this.selectedObject)
        this.objectDataShow = this.formatData({
          "reserveAccount":this.selectedObject.pubkey.toString(),
          "liquidity":{
            "mintPubkey":this.selectedObject.info.liquidity.mintPubkey.toString(),
            "mintDecimals":this.selectedObject.info.liquidity.mintDecimals,
            "supplyPubkey":this.selectedObject.info.liquidity.supplyPubkey.toString(),
            "feeReceiver":this.selectedObject.info.liquidity.feeReceiver.toString(),
            "usePythOracle":this.selectedObject.info.liquidity.usePythOracle.toString(),
            "oraclePubkey":this.selectedObject.info.liquidity.params_1.toString(),
            "larixOraclePubkey":this.selectedObject.info.liquidity.params_2.toString(),
            "availableAmount":this.selectedObject.info.liquidity.availableAmount.toString(),
            "borrowedAmountWads":eX(this.selectedObject.info.liquidity.borrowedAmountWads.toString(),-18).toString(),
            "cumulativeBorrowRateWads":this.selectedObject.info.liquidity.cumulativeBorrowRateWads.toString(),
            "marketPrice":eX(this.selectedObject.info.liquidity.marketPrice.toString(),-18).toString(),
            "ownerUnclaimed":eX(this.selectedObject.info.liquidity.ownerUnclaimed.toString(),-18-this.selectedObject.info.liquidity.mintDecimals).toString(),
          },
          "collateral":{
            "mintPubkey":this.selectedObject.info.collateral.mintPubkey.toString(),
            "mintTotalSupply":this.selectedObject.info.collateral.mintTotalSupply.toString(),
            "supplyPubkey":this.selectedObject.info.collateral.supplyPubkey.toString(),
          },
          "bonus":{
            "unCollSupply":this.selectedObject.info.bonus.unCollSupply.toString(),
            "lTokenMiningIndex":this.selectedObject.info.bonus.lTokenMiningIndex.toString(),
            "borrowMiningIndex":this.selectedObject.info.bonus.borrowMiningIndex.toString(),
            "totalMiningSpeed":this.selectedObject.info.bonus.totalMiningSpeed.toString(),
            "supplyMineRate":this.selectedObject.info.bonus.kinkUtilRate.toString(),
          },
          "config":{
            "loanToValueRatio":this.selectedObject.info.config.loanToValueRatio.toString(),
            "liquidationThreshold":this.selectedObject.info.config.liquidationThreshold.toString(),
            "optimalBorrowRate":this.selectedObject.info.config.optimalBorrowRate.toString(),
            "maxBorrowRate":this.selectedObject.info.config.maxBorrowRate.toString(),
            "depositPaused":this.selectedObject.info.config.depositPaused,
            "borrowPaused":this.selectedObject.info.config.borrowPaused,
            "liquidationBonus":this.selectedObject.info.config.liquidationBonus.toString(),
            "liquidationPaused":this.selectedObject.info.config.liquidationPaused,
            "depositLimit":this.selectedObject.info.depositLimit.toString(),
          },
          "configFees":{
            borrowInterestFeeWad:eX(this.selectedObject.info.config.fees.borrowInterestFeeWad.toString(),-18).toString(),
            hostFee:this.selectedObject.info.config.fees.hostFeePercentage,
            flashLoanFeeWad:eX(this.selectedObject.info.config.fees.flashLoanFeeWad.toString(),-18).toString()
          }
        })
      } else if(this.selectedType.name === "raydiumBridge"){
        let singleTokenCount = 0;
        for (const reserve of this.lendingReserveArray) {
          if (!reserve.info.isLP){
            singleTokenCount++;
          }
        }
        const reserve = this.lendingReserveArray[singleTokenCount+index]
        const bridgePoolId = reserve.info.liquidity.params_1
        this.selectedObject = bridgePoolId
        const bridgePool = await getBridgePool(bridgePoolId)
        this.objectDataShow = this.formatData({
          name:reserve.info.liquidity.name,
          bridgePoolId:bridgePoolId.toString(),
          owner:bridgePool.owner.toString(),
          pendingOwner:bridgePool.pendingOwner.toString(),
          compoundAuthority:bridgePool.compoundAuthority.toString(),
          addLpWithdrawAmountAuthority:bridgePool.addLpWithdrawAmountAuthority.toString(),
          ammOpenOrders:bridgePool.ammOpenOrders.toString(),
        })
        console.log()
      }
    },
    chooseAction(index:number){
      this.selectedAction = this.actionOptions[index]
    },
    execute:async function(){
      if (this.selectedType.name == "market"){
        if (!this.selectedPool.name){
          alert('Please choose pool first!')
          return
        }
        const allReserves = this.lendingReserveArray.filter((item)=> item.info.liquidity.poolType===this.lendingMarket.info.lendingType)
        console.log('execute allReserves market')
        await controlTxDialog(
            await setLendingMarketConfig(this.lendingMarket,this.selectedAction,this.actionParameter,getWallet(),allReserves,this.lendingMarket.info.lendingProgramId)
        )
      } else if (this.selectedType.name == "reserve"){
        await controlTxDialog(
            await setReserveConfig(this.lendingMarket,this.selectedObject,this.selectedAction,this.actionParameter,getWallet(),this.lendingMarket.info.lendingProgramId)
        )
      } else if (this.selectedType.name == "oracle"){
        await controlTxDialog(
            await setOracleConfig(this.oracleInfo,this.selectedAction,this.actionParameter,getWallet(),ORACLE_PROGRAM_ID)
        )
      } else if(this.selectedType.name == "isolationOracle"){
        console.log("isolationOracle")
        await controlTxDialog(
            await setOracleConfig(this.isolationOracleInfo,this.selectedAction,this.actionParameter,getWallet(),ISOLATION_ORACLE_PROGRAM_ID)
        )
      }else if(this.selectedType.name==="larix"){
        if (this.selectedAction.key==="freezeAccount"){
          await controlTxDialog(
              await freezeAccount(getWallet(),new PublicKey(this.actionParameter))
          )
        } else if (this.selectedAction.key==="unfreezeAccount"){
          await controlTxDialog(
              await unfreezeAccount(getWallet(),new PublicKey(this.actionParameter))
          )
        } else if (this.selectedAction.key==="closeFreezeAuthority"){
          // await controlTxDialog(
          //     await closeFreezeAuthority(getWallet())
          // )
        }
      }else if (this.selectedType.name==="raydiumBridge"){
        const bridgePoolId = this.selectedObject
        await controlTxDialog(
            await setRaydiumBridgeConfig(bridgePoolId,getWallet(),this.actionParameter,this.selectedAction)
        )
      } else if (this.selectedType.name==="larixLockPool"){
        await controlTxDialog(
            await setLarixLockPoolConfig(LARIX_LOCK_POOL_ID,getWallet(),this.actionParameter,this.selectedAction)
        )
      } else if (this.selectedType.name==="saberBridge"){
        console.log()
      }
    },
    formatData(dataObject:object) {
      let str = JSON.stringify(dataObject)
      str = str.slice(1,str.length-1)
      str = str.split(',')
      return str
    },
    configParameterInput(value:string){
      console.log('value',value)
      this.actionParameter = value
    },

    ViewPortAndStyle () {
      this.clientViewPort = document.documentElement.clientWidth
      this.$store.commit('updateClientViewPort',this.clientViewPort)
    },
    getLanguageImage( value:number){
      this.languageIndex = value
    },
    openSupplyMarketsDialog(reserveDetail,status){
      this.enableFor = status
      this.selectedReserveDetail = reserveDetail
      this.$store.commit('updateSelectedReserveDetail', reserveDetail)
      this.$store.commit('updateSupplyDialogVisible', true)
      this.$store.commit('updateIsFullWithdraw',false)
    },
    openBorrowMarketsDialog(reserveDetail,status){
      this.enableFor = status
      this.selectedReserveDetail = reserveDetail
      this.$store.commit('updateSelectedReserveDetail', reserveDetail)
      this.$store.commit('updateBorrowDialogVisible', true)
      this.$store.commit('updateIsFullRepay',false)
    },
    clickBtn: async function () {

      // const publicKey = (property = 'publicKey') => {
      //   return BufferLayout.blob(32, property);
      // };
      // /**
      //  * Layout for a 64bit unsigned value
      //  */
      //
      // const uint64 = (property = 'uint64') => {
      //   return BufferLayout.blob(8, property);
      // };
      // const MintLayout = BufferLayout.struct([BufferLayout.u32('mintAuthorityOption'), publicKey('mintAuthority'), uint64('supply'), BufferLayout.u8('decimals'), BufferLayout.u8('isInitialized'), BufferLayout.u32('freezeAuthorityOption'), publicKey('freezeAuthority')]);
      // const wallet = getWallet()
      // if (wallet === null || wallet === undefined || wallet.publicKey === undefined || wallet.publicKey == null) {
      //   throw new Error("请先连接钱包")
      // }
      // const larixScretKey =  [
      //   25,
      //   115,
      //   76,
      //   85,
      //   253,
      //   219,
      //   221,
      //   2,
      //   204,
      //   118,
      //   244,
      //   174,
      //   199,
      //   118,
      //   51,
      //   0,
      //   213,
      //   171,
      //   232,
      //   250,
      //   150,
      //   178,
      //   236,
      //   131,
      //   76,
      //   206,
      //   123,
      //   106,
      //   149,
      //   166,
      //   148,
      //   175,
      //   5,
      //   22,
      //   135,
      //   73,
      //   148,
      //   87,
      //   247,
      //   111,
      //   144,
      //   237,
      //   17,
      //   255,
      //   129,
      //   51,
      //   35,
      //   62,
      //   238,
      //   159,
      //   19,
      //   151,
      //   24,
      //   195,
      //   106,
      //   133,
      //   223,
      //   249,
      //   153,
      //   80,
      //   183,
      //   173,
      //   171,
      //   241
      // ]
      // const mintAccount = new Account(larixScretKey)
      // console.log("mintAccount=",mintAccount.publicKey.toString())
      // const connection = await getConnection()
      // const balanceNeeded = await Token.getMinBalanceRentForExemptMint(connection);
      // const transactions = []
      // console.log("wallet",wallet)
      // console.log("wallet.publicKey",wallet.publicKey)
      // console.log("mintAccount",mintAccount)
      // console.log("mintAccount.publicKey",mintAccount.publicKey)
      // console.log("TOKEN_PROGRAM_ID",TOKEN_PROGRAM_ID)
      //
      // transactions.push(SystemProgram.createAccount({
      //   fromPubkey: wallet.publicKey,
      //   newAccountPubkey: mintAccount.publicKey,
      //   lamports: balanceNeeded,
      //   space: MintLayout.span,
      //   programId:TOKEN_PROGRAM_ID
      // }));
      // transactions.push(Token.createInitMintInstruction(TOKEN_PROGRAM_ID, mintAccount.publicKey, 6, wallet.publicKey, wallet.publicKey)); // Send the two instructions
      // const associatedTokenAddress = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mintAccount.publicKey, wallet.publicKey)
      // transactions.push(Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mintAccount.publicKey, associatedTokenAddress, wallet.publicKey, wallet.publicKey))
      // for (let i=0;i<10;i++){
      //   transactions.push(Token.createMintToInstruction(TOKEN_PROGRAM_ID, mintAccount.publicKey, associatedTokenAddress, wallet.publicKey, [], 1000000000000000))
      // }
      // transactions.push(Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, mintAccount.publicKey, null, "MintTokens", wallet.publicKey, []))
      // console.log("transactions=",transactions)
      // const res = await sendTransaction(
      //     connection,
      //     wallet,
      //     transactions,
      //     [mintAccount],
      //     true
      // )
      // console.log("txid=",res)

    },
    clickBtn2() {
    },
    // 存款
    supply: async function (amount:string) {
      let wallet = getWallet()
      let interceptedAmount = new BigNumber(amount).toFixed(this.selectedReserveDetail.decimals,1)
      const fromAccount = this.selectedReserveDetail.userLiquidityTokenAccount
      const toAccount = this.selectedReserveDetail.userCollateralTokenAccount
      if (!fromAccount) return
      openTxDialog('updateSupplyDialogVisible')
      await depositReserve(
          fromAccount,
          new BN(eX(interceptedAmount, this.selectedReserveDetail.decimals.toString()).toString()),
          wallet,
          this.selectedReserveDetail.reserveDetail,
          this.allReservesDetails,
          this.mining,
          toAccount===undefined?undefined:toAccount.pubkey,
          this.userObligation

      ).then(()=>{
        txSuccess('updateSupplyDialogVisible')
      }).catch(()=>{
        txFailed('updateSupplyDialogVisible')
      })
      //updateSupplyDialogVisible
      await appController.updateData(this.userObligationIndex)
    },
    // 取款
    withdraw: async function (amount:string) {
      let wallet
      try {
        wallet = getWallet()
      }
      catch (e){
        alert('get wallet failed')
      }
      // let interceptedAmount = new BigNumber(amount).toFixed(this.selectedReserveDetail.decimals,1)
      // const fromAccount = this.selectedReserveDetail.userLiquidityTokenAccount
      // const toAccount = this.selectedReserveDetail.userCollateralTokenAccount
      let interceptedAmount
      let fromAccount
      let toAccount
      try {
        interceptedAmount = new BigNumber(amount).times(this.selectedReserveDetail.exchangeRate).toFixed(this.selectedReserveDetail.decimals,1)
        fromAccount = this.selectedReserveDetail.userLiquidityTokenAccount
        toAccount = this.selectedReserveDetail.userCollateralTokenAccount
      }catch (e){
        alert('get userInfo failed')
      }
      openTxDialog('updateSupplyDialogVisible')
      await redeemReserve(
                  toAccount,
                  this.isFullWithdraw?
                      new BN(-1):new BN(eX(interceptedAmount,this.selectedReserveDetail.decimals.toString()).toString()),
                  wallet,
                  this.selectedReserveDetail.reserveDetail,
                  fromAccount,
                  this.lendingReserveArray,
                  this.mining,
                  this.userObligation
              ).then(()=>{
        txSuccess('updateSupplyDialogVisible')
      }).catch(()=> {
        txFailed('updateSupplyDialogVisible')
      })

      await appController.updateData(this.userObligationIndex)
    },
    borrow: async function (amount:string) {
      let wallet = getWallet()
      let interceptedAmount = new BigNumber(amount).toFixed(this.selectedReserveDetail.decimals,1)
      openTxDialog('updateBorrowDialogVisible')
      let borrowResult = await borrow(
          this.userObligation,
          this.selectedReserveDetail.reserveDetail,
          eX(interceptedAmount,this.selectedReserveDetail.decimals.toString()).toNumber(),
          wallet,
          this.lendingReserveArray,
          this.selectedReserveDetail.userLiquidityTokenAccount)
      .then(()=>{
        txSuccess('updateBorrowDialogVisible')
        console.log('tx=',borrowResult)
      }).catch(()=>{
        txFailed('updateBorrowDialogVisible')
            console.log('tx=',borrowResult)
          })

      await appController.updateData(this.userObligationIndex)
    },
    repay: async function (amount:string) {
      let wallet = getWallet()
      let interceptedAmount = new BigNumber(amount).toFixed(this.selectedReserveDetail.decimals,1)
      openTxDialog('updateBorrowDialogVisible')
      if(this.isFullRepay)
      {
        amount = -1

          await repay(
                this.userObligation,
                this.selectedReserveDetail.reserveDetail,
                this.selectedReserveDetail.userLiquidityTokenAccount,
                amount,
                wallet,
                this.lendingReserveArray
            ).then(()=>{
              txSuccess('updateBorrowDialogVisible')
          }).catch(()=>{
            txFailed('updateBorrowDialogVisible')
          })

      }else{
        await repay(
            this.userObligation,
            this.selectedReserveDetail.reserveDetail,
            this.selectedReserveDetail.userLiquidityTokenAccount,
            eX(interceptedAmount,this.selectedReserveDetail.decimals.toString()).toNumber(),
            wallet,
            this.lendingReserveArray
        ).then(()=>{
          txSuccess('updateBorrowDialogVisible')
        }).catch(()=>{
          txFailed('updateBorrowDialogVisible')
        })
      }
      await  appController.updateData(this.userObligationIndex)
    },
    positionLimit(){
      if (!this.userObligation){
        return false
      }
      if (this.userObligation?.info.deposits.length+this.userObligation?.info.borrows.length<5)
      {
        return false
      }
      else {
        return true
      }
    },
    closeOrOpenMortgage: async function (reserveDetail) {
      if (!reserveDetail.supplyBalanceInTokenUnit.isGreaterThan(0))return
      let wallet = getWallet()
      this.selectedReserveDetail = reserveDetail
      let moreThanLimit =  this.positionLimit()
      if (reserveDetail.isEnterMarket)
      {
        await exitObligation(
            this.userObligation,
            this.selectedReserveDetail.userCollateralTokenAccount,
            this.selectedReserveDetail.reserveDetail,
            wallet,
            this.lendingReserveArray,
            this.mining
        ).then(()=>{
          txSuccess()
        }).catch(()=>{
          txFailed()
        })
      }else {
        await intoObligation(
            this.selectedReserveDetail.reserveDetail,
            wallet,
            this.lendingMarket,
            this.selectedReserveDetail.userCollateralTokenAccount,
            this.mining,
            this.lendingReserveArray,
            moreThanLimit,
            this.userObligation,
        ).then(()=>{
          if (this.errorContext==='')
          txSuccess()
        }).catch(()=>{
          txFailed()
        })
      }
      await  appController.updateData(this.userObligationIndex)
    },
  }
})
