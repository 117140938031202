export const moduleDialog = {
    state: () => ({
        supplyDialogVisible: false,
        borrowDialogVisible: false,
        transactionDialogVisible: false,
        liquidateDialogVisible: false,
        noticeDialogVisible: false,
        isFullWithdraw:false,
        isFullRepay:false,
    }),
    getters: {
    },
    mutations: {
        updateIsFullRepay(state:any,value:boolean){
            state.isFullRepay = value
        },
        updateIsFullWithdraw(state:any,value:boolean){
            state.isFullWithdraw = value
        },
        updateSupplyDialogVisible(state: any, value: string) {
            state.supplyDialogVisible = value
        },
        updateBorrowDialogVisible(state: any, value: string) {
            state.borrowDialogVisible = value
        },
        updateTransactionDialogVisible(state: any, value: string) {
            state.transactionDialogVisible = value
        },
        updateLiquidateDialogVisible(state: any, value: string) {
            state.liquidateDialogVisible = value
        },
        updateNoticeDialogVisible(state: any, value: boolean) {
            state.noticeDialogVisible = value
        },
    },
    actions: {
    }
}