import {Detail, LendingMarket, Reserve} from "@/api/models";
import {
    CLAIM_OWNER_FEE_INSTRUCTION,
    CONFIG_INSTRUCTION,
    CONFIG_MARKET_INSTRUCTION,
    CONFIG_VALUE_TYPE, LENDING_MARKET_CONFIG_TYPES,
    RECEIVE_PENDING_OWNER_INSTRUCTION
} from "@/api/config/constent";
import BufferLayout from "buffer-layout";
import {LENDING_PROGRAM_ID, TOKEN_PROGRAM_ID} from "@/api/utils/ids";
import {getConnection, isReachTransactionLimit, sendTransaction} from "@/api/context/connection";
import {WalletAdapter} from "@/api/wallets";
import {Account, PublicKey, TransactionInstruction} from "@solana/web3.js";
import * as Layout from './../utils/layout';
import BN from "bn.js";
import {findOrCreateAccountByMint} from "@/api/actions/utils/account";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";
import {refreshReserves} from "@/api/actions/utils/refreshReserves";

async function claimOwnerFee(lendingMarket: Detail<LendingMarket>, wallet: WalletAdapter, allReserve: Detail<Reserve>[],value:any,lendingProgramId:PublicKey) {
    if (wallet.publicKey==null || wallet.publicKey===undefined){
        return
    }
    let receive
    if(value){
        receive = new PublicKey(value)
    } else {
        receive = wallet.publicKey
    }
    const instructions:TransactionInstruction[] = []
    let lastInstructionLength = 0
    const cleanupInstructions: TransactionInstruction[] = []
    let lastCleanupInstructionLength = 0
    let signers: Account[]= []
    for (const reserve of allReserve) {
        if (reserve.info.isLP){
            continue
        }
        await claimReserveOwnerFee(instructions,cleanupInstructions,signers,lendingMarket,wallet,reserve,receive,lendingProgramId)
        if (isReachTransactionLimit(await getConnection(),wallet,instructions.concat(cleanupInstructions),signers)){
            await sendTransaction(
                await getConnection(),
                wallet,
                instructions.splice(0,lastInstructionLength).concat(cleanupInstructions.splice(0,lastCleanupInstructionLength)),
                [],
                true
            )
             signers = []
        }
        lastInstructionLength = instructions.length
        lastCleanupInstructionLength = cleanupInstructions.length
    }
    return await sendTransaction(
        await getConnection(),
        wallet,
        instructions.concat(cleanupInstructions),
        [],
        true
    )
}
export async function claimReserveOwnerFee(
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    signers: Account[],
    lendingMarket: Detail<LendingMarket>,
    wallet: WalletAdapter,
    reserve: Detail<Reserve>,
    receive:PublicKey,
    lendingProgramId:PublicKey
){
    if (wallet.publicKey==null || wallet.publicKey===undefined){
        throw new Error("Wallet is not connected")
    }
    await refreshReserves(
        instructions,
        [reserve],
        lendingProgramId
    )
    receive = await findOrCreateAccountByMint(
        wallet.publicKey,
        receive,
        instructions,
        cleanupInstructions,
        reserve.info.liquidity.mintPubkey,
        signers
    )
    instructions.push(await createClaimOwnerFeeInstruction(
        lendingMarket,wallet,reserve,receive,
        lendingProgramId
    ))
}
async function createClaimOwnerFeeInstruction(
    lendingMarket: Detail<LendingMarket>,
    wallet: WalletAdapter,
    reserve: Detail<Reserve>,
    receive:PublicKey,
    lendingProgramId:PublicKey
):Promise<TransactionInstruction>{
    if (wallet.publicKey==null || wallet.publicKey===undefined){
        throw new Error("Wallet is not connected")
    }
    const [lendingMarketAuthority] = await PublicKey.findProgramAddress(
        [reserve.info.lendingMarket.toBuffer()], // which account should be authority
        lendingProgramId,
    );
    const keys = [
        {
            pubkey: reserve.info.liquidity.supplyPubkey,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: receive,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: lendingMarket.pubkey,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: wallet.publicKey,
            isSigner: true,
            isWritable: false,
        },
        {
            pubkey: lendingMarketAuthority,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: TOKEN_PROGRAM_ID,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: reserve.pubkey,
            isSigner: false,
            isWritable: true,
        },
    ]
    const dataLayout = BufferLayout.struct([
        BufferLayout.u8("instruction"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
        {
            instruction: CLAIM_OWNER_FEE_INSTRUCTION,
        },
        data,
    );
    return {
        keys,
        programId:lendingProgramId,
        data
    }
}
export async function setLendingMarketConfig(
    lendingMarket:Detail<LendingMarket>,
    configType:any,
    value:any,
    wallet: WalletAdapter,
    allReserve:Detail<Reserve>[],
    lendingProgramId:PublicKey
    ){
    //LENDING_PROGRAM_ID
    if (wallet.publicKey == null || wallet.publicKey == undefined){
        throw new Error("wallet need connection")
    }
    if (configType.key=="ReceivePendingOwner"){
        return await receivePendingOwner(lendingMarket,wallet,lendingProgramId)
    } else if (configType.key==="ClaimOwnerFee"){
        return await claimOwnerFee(lendingMarket,wallet,allReserve,value,lendingProgramId)
    }else {
        const keys = [
            {
                pubkey: lendingMarket.pubkey,
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: wallet.publicKey,
                isSigner: true,
                isWritable: false,
            },
        ]
        console.log("value=",value)
        if (configType.valueType == CONFIG_VALUE_TYPE.PUBLICKEY){
            keys.push( {
                pubkey: new PublicKey(value),
                isSigner: false,
                isWritable: false,
            })
        }
        let data;
        if (configType.valueType == CONFIG_VALUE_TYPE.PUBLICKEY){
            const dataLayout = BufferLayout.struct([
                BufferLayout.u8("instruction"),
                BufferLayout.u8("config_type_instruction"),
                BufferLayout.u8("market_type_instruction"),
            ]);
            data = Buffer.alloc(dataLayout.span);
            dataLayout.encode(
                {
                    instruction: CONFIG_INSTRUCTION,
                    config_type_instruction:CONFIG_MARKET_INSTRUCTION,
                    market_type_instruction:configType.instruction,
                },
                data,
            );
        } else if (configType.valueType == CONFIG_VALUE_TYPE.NONE){
            const dataLayout = BufferLayout.struct([
                BufferLayout.u8("instruction"),
                BufferLayout.u8("config_type_instruction"),
                BufferLayout.u8("market_type_instruction"),
            ]);
            data = Buffer.alloc(dataLayout.span);
            dataLayout.encode(
                {
                    instruction: CONFIG_INSTRUCTION,
                    config_type_instruction:CONFIG_MARKET_INSTRUCTION,
                    market_type_instruction:configType.instruction,
                },
                data,
            );
        } else if(configType.valueType == CONFIG_VALUE_TYPE.UINT16){
            const dataLayout = BufferLayout.struct([
                BufferLayout.u8("instruction"),
                BufferLayout.u8("config_type_instruction"),
                BufferLayout.u8("market_type_instruction"),
                Layout.uint16("new_value")
            ]);
            data = Buffer.alloc(dataLayout.span);
            dataLayout.encode(
                {
                    instruction: CONFIG_INSTRUCTION,
                    config_type_instruction:CONFIG_MARKET_INSTRUCTION,
                    market_type_instruction:configType.instruction,
                    new_value:new BN(value)
                },
                data,
            );
        } else if(configType.valueType == CONFIG_VALUE_TYPE.UINT64){
            const dataLayout = BufferLayout.struct([
                BufferLayout.u8("instruction"),
                BufferLayout.u8("config_type_instruction"),
                BufferLayout.u8("market_type_instruction"),
                Layout.uint64("new_value")
            ]);
            data = Buffer.alloc(dataLayout.span);
            dataLayout.encode(
                {
                    instruction: CONFIG_INSTRUCTION,
                    config_type_instruction:CONFIG_MARKET_INSTRUCTION,
                    market_type_instruction:configType.instruction,
                    new_value:new BN(value)
                },
                data,
            );
        } else {
            throw new Error("由于现在没有其他类型的参数，故没有处理。")
        }
        const transactions = [{
            keys,
            programId:lendingProgramId,
            data
        }]
        return await sendTransaction(
            await getConnection(),
            wallet,
            transactions,
            [],
            true
        )
    }

}

export async function receivePendingOwner(lendingMarket:Detail<LendingMarket>,wallet: WalletAdapter,lendingProgramId:PublicKey){
    if (wallet.publicKey==null || wallet.publicKey===undefined){
        return
    }
    const keys = [
        {
            pubkey: lendingMarket.pubkey,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: wallet.publicKey,
            isSigner: true,
            isWritable: false,
        },
    ]
    const dataLayout = BufferLayout.struct([
        BufferLayout.u8("instruction"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
        {
            instruction: RECEIVE_PENDING_OWNER_INSTRUCTION,
        },
        data,
    );
    const transactions = [{
        keys,
        programId:lendingProgramId,
        data
    }]
    return await sendTransaction(
        await getConnection(),
        wallet,
        transactions,
        [],
        true
    )
}