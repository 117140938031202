import {Detail} from "@/api/models";
import {Oracle} from "@/api/models/state/oracle";
import {WalletAdapter} from "@/api/wallets";
import {
    CONFIG_VALUE_TYPE,
    ORACLE_CONFIG_TYPES
} from "@/api/config/constent";
import {PublicKey} from "@solana/web3.js";
import BufferLayout from "buffer-layout";
import {getConnection, sendTransaction} from "@/api/context/connection";
export async function receiveOracleOwner(oracle:Detail<Oracle>,wallet:WalletAdapter,oracleProgram:PublicKey){
    if (wallet.publicKey == null){
        throw new Error("wallet need connection")
    }
    const keys = [
        {
            pubkey: oracle.pubkey,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: wallet.publicKey,
            isSigner: true,
            isWritable: false,
        }
    ]
    const dataLayout = BufferLayout.struct([
        BufferLayout.u8("instruction")
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
        {
            instruction: ORACLE_CONFIG_TYPES[2].instruction,
        },
        data,
    );
    const transactions = [{
        keys,
        programId:oracleProgram,
        data
    }]
    return await sendTransaction(
        await getConnection(),
        wallet,
        transactions,
        [],
        true
    )
}
export async function setOracleConfig(oracle:Detail<Oracle>,configType:any,value:any,wallet:WalletAdapter,oracleProgram:PublicKey){
    if (configType.key===ORACLE_CONFIG_TYPES[2].key){
        return await receiveOracleOwner(oracle,wallet,oracleProgram)
    }
    if (wallet.publicKey == null){
        throw new Error("wallet need connection")
    }
    const keys = [
        {
            pubkey: oracle.pubkey,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: oracle.info.owner,
            isSigner: true,
            isWritable: false,
        }
    ]
    if (configType.valueType == CONFIG_VALUE_TYPE.PUBLICKEY){
        keys.push( {
            pubkey: new PublicKey(value),
            isSigner: false,
            isWritable: false,
        })
    }
    let data;
    if (configType.valueType == CONFIG_VALUE_TYPE.PUBLICKEY){
        const dataLayout = BufferLayout.struct([
            BufferLayout.u8("instruction")
        ]);
        data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                instruction: configType.instruction,
            },
            data,
        );
    } else if (configType.valueType == CONFIG_VALUE_TYPE.NONE){
        const dataLayout = BufferLayout.struct([
            BufferLayout.u8("instruction")
        ]);
        data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                instruction: configType.instruction
            },
            data,
        );
    }else {
        throw new Error("由于现在没有其他类型的参数，故没有处理。")
    }
    const transactions = [{
        keys,
        programId:oracleProgram,
        data
    }]
    return await sendTransaction(
        await getConnection(),
        wallet,
        transactions,
        [],
        true
    )
}