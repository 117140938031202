export const moduleLiquidation = {
    state: () => ({
        allUserObligations: {},
        selectUserObligations:{},
    }),
    getters: {
    },
    mutations: {
        updateAllUserObligations(state:any,value:any){
            state.allUserObligations = value
        },
        updateSelectUserObligations(state:any,value:any){
            state.selectUserObligations = value
        },

    },
    actions: {
    }
}