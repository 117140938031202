import {
    Account,
    PublicKey,
    TransactionInstruction,
} from '@solana/web3.js';

import {getConnection, sendTransaction} from "../context/connection";

import {Detail, LendingMarket, Obligation, Reserve} from "@/api/models";
import {LENDING_PROGRAM_ID} from "@/api/utils/ids";

import {Mining} from "@/api/models/state/mining";
import {claimMiningMine} from "@/api/actions/utils/claimMiningMine";
import {claimObligationMine} from "@/api/actions/utils/claimObligationMine";
import {createAssociatedTokenAccount} from "@/api/actions/utils/account";
import {getObligation} from "@/api/provider/obligationProvider";
import {getMining} from "@/api/provider/miningProvider";

export const claimMine = async (
    mining:Detail<Mining>,
    obligation:Detail<Obligation>,
    larixAssociatedTokenAccount:PublicKey,
    wallet:any,
    lendingMarket:Detail<LendingMarket>,
    allReserve:Detail<Reserve>[],
    lendingProgramId:PublicKey
) => {
    console.log("claimMine")
    if (mining===undefined){
        const myMinings = await getMining(wallet.publicKey,'userAction')
        if (myMinings.length>0){
            mining = myMinings[0]
        }
    }
    if (obligation==undefined){
        const myObligations = await getObligation(wallet.publicKey,'userAction')
        if (myObligations.length>0){
            obligation = myObligations[0]
        }
    }
    if (mining===undefined && obligation==undefined){
        return
    }

    const connection =await getConnection()
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];
    if (larixAssociatedTokenAccount==undefined){
        larixAssociatedTokenAccount = await createAssociatedTokenAccount(
            connection,
            instructions,
            wallet.publicKey,
            lendingMarket.info.mineMint,
            wallet.publicKey,
        )
    }

    const [lendingMarketAuthority] = await PublicKey.findProgramAddress(
        [lendingMarket.pubkey.toBuffer()],
        lendingProgramId ,
    );
    await claimMiningMine(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        mining,
        lendingMarket.info.mineSupply,
        larixAssociatedTokenAccount,
        wallet,
        lendingMarket.pubkey,
        lendingMarketAuthority,
        allReserve,
        lendingProgramId
    )
    await claimObligationMine(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        obligation,
        lendingMarket.info.mineSupply,
        larixAssociatedTokenAccount,
        wallet,
        lendingMarket.pubkey,
        lendingMarketAuthority,
        allReserve,
        lendingProgramId
    )
    return await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    );
};
