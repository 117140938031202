import store from '../store'
// import {getConnection} from "@/api/context/connection";
// import {Context, SignatureResult} from "@solana/web3.js";

export default async function controlTxDialog(tx: Promise<any>, dialogSwitchMutation?: string) {
    if (dialogSwitchMutation) store.commit(dialogSwitchMutation, false)
    // TODO: ShowScan 控制
    // store.commit('updateConfirmDialogShowScan', false)
    store.commit('updateConfirmDialog', true)
    store.commit('updateConfirmDialogTip', 'pendingDialog.confirm')
    store.commit('updateErrorContext','')

    try {
        console.log(await tx)
    } catch (e) {
        store.commit('updateErrorModel', true)
        store.commit('updateConfirmDialogTip', 'pendingDialog.errorTips')
        if (store.state.wallet.walletAddress){
            setTimeout(() => {
                if (dialogSwitchMutation) store.commit(dialogSwitchMutation, true)
                store.commit('updateConfirmDialog', false)
                store.commit('updateErrorModel', false)
            }, 3000)
        }else {
            store.commit('updateErrorModel', false)
            store.commit('updateCheckModel', false)
        }
        return
    }
    store.commit('updateCheckModel', true)
    store.commit('updateConfirmDialogTip', 'pendingDialog.checkTips')
    setTimeout(() => {
        if (dialogSwitchMutation) store.commit(dialogSwitchMutation, false)
        store.commit('updateConfirmDialog', false)
        store.commit('updateCheckModel', false)
    }, 1200)

}
