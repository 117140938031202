
import {mapState} from "vuex";
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import {claimMine} from "../api/actions/claimMine"
import {getWallet} from "@/api/context/wallet";
import controlTxDialog from "@/controller/txDialogController";
import {formatBigNumberStr} from "@/utils/helpers";
import BigNumber from "bignumber.js";

export default defineComponent({
  name: "Reward",
  data() {
    return {
      formatBigNumberStr:formatBigNumberStr,
    }
  },
  setup(){
    const { t } = useI18n()
    return {
      t,
      larixImg: require('../assets/wallet/pop_icon_LARIX@3x.png')
    }
  },
  computed: {
    walletLarixBalance():BigNumber{
      if (this.larixTokenAccount)
      {
        //@ts-ignore
        return  new BigNumber(this.larixTokenAccount[0].info.amount).times(new BigNumber(10).pow(-6))
      }else {
        return new BigNumber(0)
      }

    },
    rewardDialogVisible:{
      get(){
        return this.$store.state.reward.rewardDialogVisible
      },
      set(value) {
        this.$store.commit('updateRewardDialogVisible',value)
      }
    },
    ...mapState({
      walletAddress: (state:any) => state.wallet.walletAddress,
      isLoadingInfo:(state:any) =>state.market.isLoadingInfo,
      lendingMarket: (state:any) => state.market.lendingMarket,
      larixTokenAccount: (state:any) => state.larix.larixTokenAccount,
      userObligation:(state:any) => state.market.userObligation,
      mining:(state:any) => state.market.mining,
      allReservesDetails: (state:any) => state.market.allReservesDetails,
      lendingReserveArray: (state:any) => state.market.lendingReserveArray,
      larixPrice:(state:any)=>state.market.larixPrice,
    })
  },
  methods: {
    claimReward: async function () {
      let wallet = getWallet()
      let that = this
      await controlTxDialog(
          //@ts-ignore
           claimMine(
              that.mining,
              that.userObligation,
              that.larixTokenAccount?.pubkey,
              wallet,
              that.lendingMarket,
              that.lendingReserveArray
          ),
          'updateRewardDialogVisible'
      )

    },
    getUnclaimedMineAmount:async function(){

    }
  }
})
