import {AccountInfo, PublicKey} from "@solana/web3.js";
import * as BufferLayout from 'buffer-layout';
import * as Layout from "@/api/utils/layout";
import {Detail} from "@/api/models";
export interface Oracle{
    owner:PublicKey,
    pendingOwner:PublicKey,
    submitAuthority:PublicKey
}
export const OracleLayout:typeof BufferLayout.Structure = BufferLayout.struct([
    BufferLayout.u8('version'),
    Layout.publicKey("owner"),
    Layout.publicKey("pendingOwner"),
    Layout.publicKey("submitAuthority")
])
export const OracleParser = (pubkey: PublicKey, info: AccountInfo<Buffer>):Detail<Oracle> => {
    const buffer = Buffer.from(info.data);
    const oracle = OracleLayout.decode(buffer) as Oracle
    const detail = {
        pubkey,
        account:{
            ...info
        },
        info: oracle
    }
    return detail
}