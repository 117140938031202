import {BRIDGE_POOL_IDL, BRIDGE_POOL_IDL_ACCOUNT, BRIDGE_POOL_PROGRAM_ID, WSSURL} from "@/api/utils/ids";
import {AccountsCoder, Program, Provider, setProvider} from "@project-serum/anchor"
import {getConnection} from "@/api/context/connection";
import {getWallet} from "@/api/context/wallet";
import {PublicKey} from "@solana/web3.js";
import {BridgePool} from "@/api/models/state/bridgePool";
let program:Program
const bridgePoolMap = new Map<string,BridgePool>();
let provider:Provider
export async function getProvider():Promise<Provider>{
    if (provider){
        return provider
    } else {
        const wallet = (window as any).solana
        return provider = new Provider(
            await getConnection(), wallet, {
                commitment:'recent'
            },
        )
    }
}
export async function getBridgeProgram():Promise<Program>{
    if (program) {
        return program
    } else {
        return program = new Program(BRIDGE_POOL_IDL,BRIDGE_POOL_PROGRAM_ID,await getProvider());
    }
}
export async function getBridgePool(bridgePoolID:PublicKey):Promise<BridgePool>{
    const bridgeProgram = await getBridgeProgram()
    return (await bridgeProgram.account.pool.fetch(bridgePoolID)) as BridgePool
}

