import {
  LENDING_PROGRAM_ID
} from "../../utils/ids"
import {
  Account,
  Connection,
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js';
import {
  depositObligationCollateralInstruction, Detail, Obligation,
  Reserve
} from '../../models';

import {WalletAdapter} from "@/api/wallets";
import BN from "bn.js";

// @FIXME
export const depositObligationCollateral = async (
    connection:Connection,
    signers: Account[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    wallet: WalletAdapter,
    collateralAmount: BN,
    sourceCollateral: PublicKey,
    detailReserve:Detail<Reserve>,
    obligation:Detail<Obligation> ,
    lendingProgramId:PublicKey
) => {
  if (wallet.publicKey == null){
    throw new Error("wallet need connection")
  }
  const reserve = detailReserve.info;
  const reserveAddress = detailReserve.pubkey
  // user from account
  const [lendingMarketAuthority] = await PublicKey.findProgramAddress(
    [reserve.lendingMarket.toBuffer()],
      lendingProgramId ,
  );


  instructions.push(
    depositObligationCollateralInstruction(
      collateralAmount,
      sourceCollateral,
      reserve.collateral.supplyPubkey,
      reserveAddress,
        obligation.pubkey,
      reserve.lendingMarket,
      lendingMarketAuthority,
      // @FIXME: wallet must sign
      wallet.publicKey,
      wallet.publicKey,
        lendingProgramId
    ),
  );
};
