
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import {useI18n} from 'vue-i18n'
import useQuasar from 'quasar/src/composables/use-quasar.js';
// import {getTestToken} from "@/api/actions/utils/getTestToken";
import {connect, disconnect} from "@/api/context/wallet";
import appController from "@/controller/appController";
//getWallet
export default defineComponent({
  name: "Wallet",
  data() {
    return {
      wallets: {
        // Ledger: '',
        Phantom: '',
        'Sollet Extension': '',
        // TrustWallet: '',
        MathWallet: '',
        Coin98:'',
        Slope : '',
        Solong: '',
        Solflare: 'https://solflare.com/access-wallet',
        // Bonfida: 'https://bonfida.com/wallet'
        // https://docs.coin98.app/coin98-extension/developer-guide
        // Coin98: ''
        // ezDeFi: '',
      },
      iconImg: [
        {
          src: require('../assets/wallet/pop_wallet_logo_phantom@2x.png')
        },
        {
          src: require('../assets/wallet/pop_wallet_logo_sollet@2x.png')
        },

        {
          src: require('../assets/wallet/pop_wallet_logo_mathwalle@2x.png')
        },
        {
          src: require('../assets/wallet/pop_wallet_logo_coin98@2x.png')
        },
        {
          src: require('../assets/wallet/pop_wallet_logo_slope@2x.png')
        },
        {
          src: require('../assets/wallet/pop_wallet_logo_solong@2x.png')
        },
        {
          src: require('../assets/wallet/pop_wallet_logo_solflare@2x.png')
        }
      ]
    }
  },
  setup() {
    const {t} = useI18n()
    const $q = useQuasar()
    return {
      t,
      $q
    }
  },
  computed: {

    walletDialogVisible: {
      get() {
        return this.$store.state.wallet.walletDialogVisible
      },
      set(value) {
        this.$store.commit('updateWalletDialogVisible', value)
      }
    },
    ...mapState({
      walletAddress: (state: any) => state.wallet.walletAddress,
      isLoadingInfo: (state: any) => state.market.isLoadingInfo,
      userObligationIndex :(state:any)=> state.market.userObligationIndex,
      userAllObligation :(state:any)=> state.market.userAllObligation
    })
  },
  methods: {
    async changeObligation(userObligationIndex:number){
      this.$store.commit('updateUserObligationIndex',userObligationIndex)
      this.$store.commit('updateWalletDialogVisible', false)
      await appController.updateData(userObligationIndex)
    },
    async connect(walletName: string) {
      const res = await connect(walletName)
      console.log('connect context',res)
      const test  =  res?.message
      const url = res?.installUrl
      if (url){
        this.$q.notify({
          message:test,
          position:'bottom-left',
          actions: [
            { label: 'Install', color: 'red', handler: () =>
              {
                window.open(url)
              }
            }
          ],
          timeout:8000
        })
      }
    },

    disconnect() {
      disconnect()
    },

    // faucet() {
    //   let wallet = getWallet()
    //   // @ts-ignore
    //   getTestToken(wallet, wallet.publicKey)
    //   this.walletDialogVisible = false
    // }
  }
})
