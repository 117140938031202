import {Detail, refreshReserveInstruction, Reserve} from "@/api/models";
import {Account, Connection, PublicKey, Signer, TransactionInstruction} from "@solana/web3.js";
import {refreshReserves} from "@/api/actions/utils/refreshReserves";

export const refreshReserve = async (
    connection:Connection,
    signers: Signer[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    reserve:Detail<Reserve>,
    lendingProgramId:PublicKey
)=>{
    await refreshReserves(
        instructions,
        [reserve],
        lendingProgramId
    )
}