
import {
  Detail,
  redeemReserveCollateralInstruction,
  Reserve,
} from "../../models";
import {
  LENDING_PROGRAM_ID
} from "../../utils/ids"
import {
  Account, Connection,
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js';
import BN from "bn.js";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";

// @FIXME
/**
 *
 * @param source in collateral
 * @param collateralAmount
 * @param wallet
 * @param reserve
 * @param reserveAddress
 * @param destinationLiquidity
 */
export const redeemReserveCollateral = async (
    connection:Connection,
    signers: Account[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    sourceCollateral: PublicKey,
    collateralAmount: BN,
    wallet: any,
    detailReserve:Detail<Reserve>,
    destinationLiquidity:PublicKey,
    lendingProgramId:PublicKey
) => {
  const reserve = detailReserve.info
  const reserveAddress = detailReserve.pubkey
  // user from account

  const [lendingMarketAuthority] = await PublicKey.findProgramAddress(
      [reserve.lendingMarket.toBuffer()], // which account should be authority
      lendingProgramId ,
  );

  // create approval for transfer transactions
  refreshReserve(
      connection,
      signers,
      instructions,
      cleanupInstructions,
      detailReserve,
      lendingProgramId
  )
  instructions.push(
    redeemReserveCollateralInstruction(
      collateralAmount,
      sourceCollateral,
      destinationLiquidity,
      reserveAddress,
      reserve.collateral.mintPubkey,
      reserve.liquidity.supplyPubkey,
      reserve.lendingMarket,
      lendingMarketAuthority,
      wallet.publicKey,
        lendingProgramId
    ),
  );

};
