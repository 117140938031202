// @ts-ignore
import {PublicKey} from "@solana/web3.js";
import { LENDING_ARRAY } from "../utils/ids";

export const CONFIG_INSTRUCTION = 14;
export const RECEIVE_PENDING_OWNER_INSTRUCTION = 23;
export const CLAIM_OWNER_FEE_INSTRUCTION = 22;
export const CONFIG_MARKET_INSTRUCTION = 0;
export const CONFIG_RESERVE_INSTRUCTION = 1;
export const CONFIG_TYPE = {
    LENDING_MARKET:"LendingMarket",
    RESERVE:"Reserve"
}
export const CONFIG_VALUE_TYPE = {
    PUBLICKEY:"publickey",
    UINT8:"uint8",
    UINT64:"uint64",
    UINT16:"uint16",
    BOOL:"bool",
    NONE:"none"
}
export const LENDING_MARKET_CONFIG_TYPES = [
    {
        key: "ClaimOwnerFee",
        title: "Claim Owner Fee",
        instruction: -1,
        valueType: CONFIG_VALUE_TYPE.NONE
    },
    {
        key:"Owner",
        title:"possessor",
        instruction:0,
        valueType:CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key:"OracleProgram",
        title:"Pyth Oracle program ID",
        instruction:1,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "TokenProgramID",
        title: "SPL-Token program ID",
        instruction: 2,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "LarixOracleProgram",
        title: "Larix Oracle program ID",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },

    {
        key: "MineMint",
        title: "LARIX Token address",
        instruction: 4,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "MineSupplyAccount",
        title: "LARIX Token provide account",
        instruction: 5,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "ReceivePendingOwner",
        title: "ReceivePendingOwner",
        instruction: -1,
        valueType: CONFIG_VALUE_TYPE.NONE
    },
    {
        key: "MineLockProgram",
        title: "Mine Lock Program",
        instruction: 6,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "LockLarixTimesToTime",
        title: "Lock Larix Times To Time",
        instruction: 7,
        valueType: CONFIG_VALUE_TYPE.UINT64
    },
    {
        key: "MaxClaimTimes",
        title: "Max Claim Times",
        instruction: 8,
        valueType: CONFIG_VALUE_TYPE.UINT16
    }


]
export const RESERVE_CONFIG_TYPES = [
    {
        key: "ClaimOwnerFee",
        title: "Claim Owner Fee",
        instruction: -1,
        valueType: CONFIG_VALUE_TYPE.NONE
    },
    {
        key:"Oracle",
        title: "Oracle",
        instruction:0,
        valueType:CONFIG_VALUE_TYPE.PUBLICKEY,
    },
    {
        key:"FeeReceiver",
        title:"FeeReceiver",
        instruction:1,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY,
    },
    {
        key: "OptimalUtilizationRate",
        title: "OptimalUtilizationRate",
        instruction: 2,
        valueType: CONFIG_VALUE_TYPE.UINT8
    },
    {
        key: "LoanToValueRatio",
        title: "LoanToValueRatio",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.UINT64
    },
    {
        key: "LiquidationBonus",
        title: "LiquidationBonus",
        instruction: 4,
        valueType: CONFIG_VALUE_TYPE.UINT8
    },
    {
        key: "LiquidationThreshold",
        title: "LiquidationThreshold",
        instruction:5,
        valueType: CONFIG_VALUE_TYPE.UINT8
    },
    {
        key: "MinBorrowRate",
        title: "MinBorrowRate",
        instruction: 6,
        valueType: CONFIG_VALUE_TYPE.UINT8
    },
    {
        key: "OptimalBorrowRate",
        title: "OptimalBorrowRate",
        instruction: 7,
        valueType: CONFIG_VALUE_TYPE.UINT8,
    },
    {
        key: "MaxBorrowRate",
        title: "MaxBorrowRate" ,
        instruction: 8,
        valueType: CONFIG_VALUE_TYPE.UINT8,
    },
    {
        key: "BorrowFeeWad",
        title: "BorrowFeeWad",
        instruction: 9,
        valueType: CONFIG_VALUE_TYPE.UINT64,
    },
    {
        key: "ReserveOwnerFeeWad",
        title: "ReserveOwnerFeeWad",
        instruction: 10,
        valueType: CONFIG_VALUE_TYPE.UINT64
    },
    {
        key: "FlashLoanFeeWad",
        title: "FlashLoanFeeWad",
        instruction: 11,
        valueType: CONFIG_VALUE_TYPE.UINT64
    },
    {
        key: "HostFeePercentage",
        title: "HostFeePercentage",
        instruction: 12,
        valueType: CONFIG_VALUE_TYPE.UINT8
    },
    {
        key: "DepositPaused",
        title: "DepositPaused",
        instruction: 13,
        valueType: CONFIG_VALUE_TYPE.BOOL,
    },
    {
        key: "BorrowPaused",
        title: "BorrowPaused",
        instruction: 14,
        valueType: CONFIG_VALUE_TYPE.BOOL,
    },
    {
        key: "LiquidationPaused",
        title: "LiquidationPaused",
        instruction: 15,
        valueType: CONFIG_VALUE_TYPE.BOOL
    },
    {
        key: "MiningSpeed",
        title: "MiningSpeed",
        instruction: 16,
        valueType: CONFIG_VALUE_TYPE.UINT64,
    },
    {
        key: "SupplyMineRate",
        title: "SupplyMineRate",
        instruction: 17,
        valueType: CONFIG_VALUE_TYPE.UINT64
    },
    {
        key: "DepositLimit",
        title: "DepositLimit",
        instruction: 20,
        valueType: CONFIG_VALUE_TYPE.UINT64
    }
]
export const ORACLE_CONFIG_TYPES = [
    {
        key: "SetSubmitAuthority",
        title: "set Submit",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "SetOraclePendingOwner",
        title: "SetOraclePendingOwner",
        instruction: 4,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "ReceiveOraclePendingOwner",
        title: "ReceiveOraclePendingOwner",
        instruction: 5,
        valueType: CONFIG_VALUE_TYPE.NONE
    }
]
export const RAYDIUM_BRIDGE_TYPES = [
    {
        key: "SetPendingOwner",
        title: "Set Pending Owner",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "ReceivePendingOwner",
        title: "Receive Pending Owner",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "SetCompoundAuthority",
        title: "Set Compound Authority",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "SetAmmOpenOrders",
        title: "Set Amm OpenOrders",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    }
]
export const SABER_BRIDGE_TYPES = [
    {
        key: "SetPendingOwner",
        title: "Set Pending Owner",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "ReceivePendingOwner",
        title: "Receive Pending Owner",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
]
export const LARIX_LOCK_POOL_TYPES = [
    {
        key: "SetPendingOwner",
        title: "Set Pending Owner",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
    {
        key: "ReceivePendingOwner",
        title: "Receive Pending Owner",
        instruction: 3,
        valueType: CONFIG_VALUE_TYPE.PUBLICKEY
    },
]
export const CONFIG_TYPES = [
    {
        name:"market",
        actions:LENDING_MARKET_CONFIG_TYPES,
    },
    {
        name:"reserve",
        actions: RESERVE_CONFIG_TYPES
    },
    {
        name:"oracle",
        actions: ORACLE_CONFIG_TYPES
    },
    {
        name: "isolationOracle",
        actions: ORACLE_CONFIG_TYPES
    },
    {
        name:"larix",
        actions:[
            {
                key: "freezeAccount",
                title: "Freeze Account",
                instruction: 0,
                valueType: CONFIG_VALUE_TYPE.NONE
            },
            {
                key: "unfreezeAccount",
                title: "Unfreeze Account",
                instruction: 0,
                valueType: CONFIG_VALUE_TYPE.NONE
            },
            {
                key: "closeFreezeAuthority",
                title: "Close Freeze Authority",
                instruction: 0,
                valueType: CONFIG_VALUE_TYPE.NONE
            },
        ]
    },
    {
        name:"raydiumBridge",
        actions: RAYDIUM_BRIDGE_TYPES
    },
    {
        name:"larixLockPool",
        actions: LARIX_LOCK_POOL_TYPES
    },
    {
        name:"saberBridge",
        actions: SABER_BRIDGE_TYPES
    }
    ]
export const LENDING_MARKETS = [
    {
        lendingMarket:new PublicKey("AxqLjsixAdJzLR9McwEV48yQFTr8WEwDrQBYi9UWb2oy"),
        instruction:0,
        types:LENDING_MARKET_CONFIG_TYPES,
        reserves:[
            new PublicKey("DciDUVkAmeNEsbtFAqfC6z7SUFBTyghWKkhHktER7yfA"),
            new PublicKey("4GUnT24Q8w4fBTxpNAg78PNDf8GNUneP5kxEr4Y5KiBn"),
            new PublicKey("ESEhoQn3iLG8w8E6id3QbuY43rqnxxqAcTNF1ZGmSDy5"),
            new PublicKey("2fhkZQwa6kp9jT9giiQFdozRvoY7zMmC3PqDCtk6mn6h"),
            new PublicKey("6nCeEWf8fyQcCsZTohwzzkGucHyT53z5VaWfvb6dkmoY"),
        ]
    }
]
export const POOL_TYPES = LENDING_ARRAY.map((lendingMarket)=>{
    return {
        name:lendingMarket.lendingName
    }
})
