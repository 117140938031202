import {getBridgeProgram} from "@/api/context/bridgePool";
import {PublicKey, TransactionInstruction} from "@solana/web3.js";
import {getConnection, sendTransaction} from "@/api/context/connection";

export async function setRaydiumBridgeConfig(bridgePoolId:PublicKey,wallet:any,value:any,actionType:any){
   const raydiumBridgeProgram = await getBridgeProgram()

   console.log('raydiumBridgeProgram', raydiumBridgeProgram)
   const instructions:TransactionInstruction[] = []
   if (actionType.key==="SetPendingOwner"){
      const pendingOwner = new PublicKey(value)
      instructions.push( await raydiumBridgeProgram.instruction.setPendingPool(pendingOwner, {
         accounts: {
            pool: bridgePoolId,
            owner:wallet.publicKey,
         },
      }))
   } else if (actionType.key ==="ReceivePendingOwner"){
      instructions.push( await raydiumBridgeProgram.instruction.receivePendingPool({
         accounts: {
            pool: bridgePoolId,
            pendingOwner:wallet.publicKey,
         },
      }))
   } else if (actionType.key === "SetCompoundAuthority"){
      const newCompoundAuthority = new PublicKey(value)
      instructions.push( await raydiumBridgeProgram.instruction.setCompoundAuthority(newCompoundAuthority,{
         accounts: {
            pool: bridgePoolId,
            owner:wallet.publicKey,
         },
      }))
   } else if (actionType.key === "SetAmmOpenOrders"){
      const newAmmOpenOrders = new PublicKey(value)
      instructions.push( await raydiumBridgeProgram.instruction.setAmmOpenOrders(newAmmOpenOrders,{
         accounts: {
            pool: bridgePoolId,
            owner:wallet.publicKey,
         },
      }))
   }

   return await sendTransaction(
       await getConnection(),
       wallet,
       instructions,
       [],
       true
   )
}
