import {Account, Connection, PublicKey, TransactionInstruction} from "@solana/web3.js";
// @ts-ignore
import {createClaimMiningMineInstruction} from "@/api/models/instructions/claimMiningMine";
import {Detail, Reserve} from "@/api/models";
import {Mining} from "@/api/models/state/mining";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";


export async function claimMiningMine(
    connection:Connection,
    signers: Account[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    mining:Detail<Mining>,
    miningSupply:PublicKey,
    larixAssociatedTokenAccount:PublicKey,
    wallet:any,
    lendingMarket:PublicKey,
    lendingMarketAuthority:PublicKey,
    allReserve:Detail<Reserve>[],
    lendingProgramId:PublicKey

){
    if (mining==undefined){
        return
    }
    mining.info.miningIndices.map((item)=>{
        allReserve.map((reserve)=>{
            if (item.reserve.equals(reserve.pubkey)){
                refreshReserve(
                    connection,
                    signers,
                    instructions,
                    cleanupInstructions,
                    reserve,
                    lendingProgramId
                )
            }
        })
    })
    instructions.push(createClaimMiningMineInstruction(
        mining,
        miningSupply,
        larixAssociatedTokenAccount,
        wallet.publicKey,
        lendingMarket,
        lendingMarketAuthority,
        lendingProgramId
    ))
}