
import {
  Account,
  Connection,
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js';
import {LENDING_PROGRAM_ID} from "../../utils/ids"
import {
  withdrawObligationCollateralInstruction,
  Reserve,
  Detail,
  Obligation,
} from '../../models';
import {WalletAdapter} from "@/api/wallets";
import BN from "bn.js";
import {refreshObligation} from "@/api/actions/utils/refreshObligation";

// @FIXME
export const withdrawObligationCollateral = async (
    connection:Connection,
    signers: Account[],
    instructions: TransactionInstruction[],
    cleanupInstructions: TransactionInstruction[],
    obligationDetail: Detail<Obligation>,
    collateralAmount: BN,
    destinationCollateral:PublicKey,
    detailReserve:Detail<Reserve>,
    wallet: WalletAdapter,
    allReserve:Array<Detail<Reserve>>,
    lendingProgramId:PublicKey
) => {
  if (wallet.publicKey == null){
    throw new Error("wallet need connection")
  }
  const reserve = detailReserve.info
  const reserveAddress = detailReserve.pubkey
  const [lendingMarketAuthority] = await PublicKey.findProgramAddress(
    [reserve.lendingMarket.toBuffer()],
      lendingProgramId ,
  );
  refreshObligation(
      connection,
      signers,
      instructions,
      cleanupInstructions,
      obligationDetail,
      allReserve,
      lendingProgramId
  )
  // get destination account
  instructions.push(
    withdrawObligationCollateralInstruction(
      collateralAmount,
      reserve.collateral.supplyPubkey,
      destinationCollateral,
      reserveAddress,
      obligationDetail.pubkey,
      reserve.lendingMarket,
      lendingMarketAuthority,
      // @FIXME: wallet must sign
      wallet.publicKey,
        lendingProgramId
    ),
  );
};
