import {
    Account,
    Connection,
    sendAndConfirmTransaction,
    Signer,
    Transaction,
    TransactionInstruction
} from "@solana/web3.js";
import {WalletAdapter} from "@/api/wallets/types";
import store from '../../store'
import {URL, WSSURL} from "@/api/utils/ids";
import {Provider} from "@project-serum/anchor";


let connection:Connection
let testConnection:Connection
let _wallet:WalletAdapter
export function setWallet(wallet:any){
    _wallet = wallet
}
export function getWallet(){
    return _wallet
}
export function isReachTransactionLimit(
    connection: Connection,
    wallet: any,
    instructions: TransactionInstruction[],
    signers: Signer[]
){
    return getTransactionLength(connection,wallet,instructions,signers) > 1232
}
export function getTransactionLength(
    connection: Connection,
    wallet: any,
    instructions: TransactionInstruction[],
    signers: Signer[]
){
    const transaction = new Transaction();
    transaction.recentBlockhash = "8DCjq36FJve3BGPc5tu7emWCxG1deqPJzGdRBWTXeGqJ"
    transaction.feePayer = wallet.publicKey
    instructions.forEach((instruction) => transaction.add(instruction));
    return getTransactionLengthInner(transaction,signers)
}
function getTransactionLengthInner(transaction:Transaction,signers:Signer[]){
    return transaction.serializeMessage().length+signers.length*64+65
}
export function getConnection():Connection{
    if (connection) {return connection}
    connection = new Connection(URL, {
        commitment:'recent'
    });
    return connection;
}
export async function getDevConnection():Promise<Connection>{
    if (testConnection) {return testConnection}
    testConnection = new Connection(URL, 'recent');
    return testConnection;
}
export function updateConnection(url:string):void{
    connection = new Connection(url, {
        commitment:'recent'
    });
}
export function useConnection():Connection{
    return connection
}
let provider:Provider
export async function getProvider():Promise<Provider>{
    if (provider){
        return provider
    } else {
        const wallet = (window as any).solana
        return provider = new Provider(
            await getConnection(), wallet, {
                commitment:'recent'
            },
        )
    }
}

export const sendTransaction = async (
    connection: Connection,
    wallet: WalletAdapter,
    instructions: TransactionInstruction[],
    signers: Account[],
    awaitConfirmation = true
) => {
    if (!wallet?.publicKey) {
        throw new Error("Wallet is not connected");
    }

    let transaction = new Transaction();
    instructions.forEach((instruction) => transaction.add(instruction));
    console.log("instructions",instructions)
    transaction.recentBlockhash = (
        await connection.getRecentBlockhash("max")
    ).blockhash;
    transaction.setSigners(
        // fee payied by the wallet owner
        wallet.publicKey,
        ...signers.map((s) => s.publicKey)
    );
    if (signers.length > 0) {
        transaction.partialSign(...signers);
    }
    transaction = await wallet.signTransaction(transaction);
    const rawTransaction = transaction.serialize();
    console.log(rawTransaction.length)
    const options = {
        skipPreflight: true,
        commitment: "processed",
    };

    const txid = await connection.sendRawTransaction(rawTransaction, options);

    // TODO: 交易发出，UI反馈
    if (awaitConfirmation) {
        const status = (
            await connection.confirmTransaction(
                txid,
                "confirmed"
            )
        ).value;
        console.log("txid=",txid)

        if (status.err) {
            //@ts-ignore
            store.commit('updateErrorContext',status.err?.InstructionError[1].Custom)
            console.log(status.err)
            throw new Error(status.err.toString())
        }
    }

    return txid;
};
