import {PublicKey, TransactionInstruction} from "@solana/web3.js";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {getLarixLockProgram} from "@/api/context/larixLock";

export async function setLarixLockPoolConfig(larixLockPoolId:PublicKey,wallet:any,value:any,actionType:any){
    const program = await getLarixLockProgram()

    const instructions:TransactionInstruction[] = []
    if (actionType.key==="SetPendingOwner"){
        const pendingOwner = new PublicKey(value)
        instructions.push( await program.instruction.setPendingOwner(pendingOwner, {
            accounts: {
                pool: larixLockPoolId,
                owner:wallet.publicKey,
            },
        }))
    } else if (actionType.key ==="ReceivePendingOwner"){
        instructions.push( await program.instruction.receivePendingOwner({
            accounts: {
                pool: larixLockPoolId,
                pendingOwner:wallet.publicKey,
            },
        }))
    }

    return await sendTransaction(
        await getConnection(),
        wallet,
        instructions,
        [],
        true
    )
}