
export default {
    name: 'English',
    headers:{
        dashBoard:'Dashboard',
        market:'Market',
        liquidation:'Liquidation',
        larix:'LARIX',
        stake:'Stake',
        dao:'DAO',
        connect:'Connect',
        About:'About',
        reward:'Reward',
    },
    reward:{
        connected:"Connected",
        larixBalance:'LARIX Balance',

    },
    wallet:{
        reward:'Reward',
        connect:'Connect',
        faucet:'Faucet',
        connectToAWallet:'Connect to a wallet',
        yourWallet:'Your Wallet',
        disconnect:'Disconnect',
        connected:'Connected',
        harvest:'HARVEST',
        walletBalance: 'Wallet Balance',
        unclaimedBalance: 'Unclaimed Balance',
        price: 'Price',
        nothingToClaim: 'Nothing to claim',
        subAccount :'sub-account',
    },
    footer:{
        doc:'Doc'
    },
    home:{
      totalSupply:'Total Supply',
      totalBorrow:'Total Borrow',
      totalMined:'Total Mined',
      larixPrice:'Larix Price',
      supplyMarket:'Supply Market',
      borrowMarket:'Borrow Market',
      myBill:'My Bill',
      asset:'Asset',
      collateral:'Collateral',
      allMarket:'All Market',
      supplyAPY:'Supply APY',
      wallet:'Wallet',
        borrowAPY:"Borrow APY",
        liquidity:"Liquidity",
        operation:"Operation",
        borrowBalance:"Borrow Balance",
        supplyBalance:"Supply Balance",
        withdraw:"Withdraw",
        repay:"Repay",
        supply:"Supply",
        borrow:"Borrow",
        loading:"Loading",
        connectToAWallet:'Connect to a wallet',

    },
    supplyAndBorrow:{
        borrowLimit:"Borrow Limit",
        netAPY:"Net APY",
        borrowBalance:"Borrow Balance",
        supplyBalance:"Supply Balance",

    },
    supplyDialog: {
        supply: "Supply",
        withDraw: "Withdraw",
        wallet: "Wallet",
        balance: "Balance",
        walletBalance: "wallet",
        liquidity: "liquidity",
        max: "MAX",
        limit85: "85% LIMIT",
        supplyApy: "Supply APY",
        supplyBalance: "Supply Balance",
        borrowLimit: "Borrow Limit",
        supplyRate: "Supply Rate",
        withdrawRate: "Withdraw Rate",
        invalidNumber:"Invalid Number",
        insufficientLiquidity:"Insufficient Liquidity",
        stillWithdraw:"Withdraw Anyway",
        borrowLimitUsedPercent:"Borrow Limit Utilization Rate",
        collateralFactor:"Collateral Factor",
        insufficientFunds:"Insufficient Funds",
        cancel:'Cancel',
        interestRate: "Interest Rate",
        miningAPY: "Mining APY",
        supplyMaxLimit100M:"The maximum supply amount is limited to $100 million.",

    },
    borrowDialog: {
        borrow: "Borrow",
        repay: "Repay",
        wallet: "Wallet",
        balance: "Balance",
        walletBalance: "wallet",
        liquidity: "liquidity",
        borrowMax: "MAX",
        max:'MAX',
        borrowApy: "Borrow APY",
        borrowBalance: "Borrow Balance",
        borrowLimit: "Borrow Limit",
        borrowRate: "Borrow Rate",
        repayRate: "Repay Rate",
        borrowMaxInTokenUnit:"Borrow Max",
        borrowLimitUsedPercent:"Borrow Limit Utilization Rate",
        collateralFactor:"Collateral Factor",
        invalidNumber:"Invalid Number",
        insufficientLiquidity:"Insufficient Liquidity",
        stillBorrow:"Borrow Anyway",
        noBalanceToRepay:"No Balance To Repay",
        insufficientFunds:"Insufficient Funds",
        cancel:'Cancel',
        interestRate: "Interest Rate",
        miningAPY: "Mining APY",
        reachPositionLimit:'Each account is limited to 5 collateral and borrowing interactions in total, simultaneously.',
    },
    market:{
        supply:"Supply",
        borrow:"Borrow",
        top3Markets:"Top 3 Markets",
        asset:"Asset",
        totalSupply:"Total Supply",
        supplyAPY:"Supply APY",
        totalBorrow:"Total Borrow",
        borrowAPY:"Borrow APY",
        details:"Details",
        supplyBorrow:'Total Market Value',
        loading:"Loading",
        connectToAWallet:'Connect to a wallet',
        totalSupplyApy:"Total Supply/APY",
        totalBorrowApy:"Total Borrow/APY",
        depositLimit:"Deposit Limit",
        supplyIR:'Supply Interest',
        borrowIR:'Borrow Interest',
        supplyDistributionAPR:'Supply Distribution APR',
        borrowDistributionAPR:'Borrow Distribution APR',
        supplyIRHelpContext:'Supply APY = Supply Interest + Supply Mining APR',
        borrowIRHelpContext:'Borrow APY = Borrow Interest - Borrow Mining APR'
    },
    marketId:{
        market:"Market",
        supply:"Supply",
        borrow:"Borrow",
        netRate:"Net APY",
        supplyAPY:"Supply APY",
        distributionSupplyAPY:"Distribution APY",
        distributionBorrowAPY:"Distribution APY",
        totalSupply:"Total Supply",
        borrowAPY:"Borrow APY",
        totalBorrow:"Total Borrow",
        interestRateModel:"Interest Rate Model",
        marketDetails:"Market Details",
        Price:"Price",
        marketLiquidity:"Market Liquidity",
        collateralFactor:"Collateral Factor",
        exchangeRate:"Exchange Rate",
        supplyMiningAPR:"Supply Mining APR",
        borrowMiningAPR:"Borrow Mining APR",
        utilization:"Utilization",
        supplyChartAPY:"Supply APY",
        borrowChartAPY:"Borrow APY",
        mndeAPR:"MNDE APR"
    },
    larix:{
        amountinCirculation:"Amount in Circulation",
        circulatingMarketCap:"Circulating Market Cap",
        totalSupply:"Total Amount",
        lARIXDistribution:"LARIX Distribution",
        market:"Market",
        perDay:"Daily Mining",
        supplyMiningAPR:"Supply Mining APR",
        borrowMiningAPR:"Borrow Mining APR",
        dailyDistribution:"Daily Distribution",
        other:"Other",
        distribution:"Distribution"
    },
    About:{
        aboutLarix:"About Larix",
        aboutLarixContext:"Larix is the ultimate lending gateway on Solana, adopted a dynamic interest rate model and created more capital-efficient risk management pools, as such a broad selection of collateral types, crypto tokens, stablecoins, synthetic assets, NFTs, and other kind of assets can be fully utilized in a safe way.",
        auditReport:"Audit Report",
        whyLarix:"Why Larix",
        reason1:"The first lending protocol with live mining functionality",
        reason2:"The first lending protocol will support NFTs peer to peer lending",
        reason3:"The first lending protocol partially Open-Sourced on Solana",
        roadmap:"Roadmap",
        roadmapStep1:"Phase 1: Jun – Sep 2021",
        roadmapStep2:"Phase 2: Late 2021 to 2022",
        roadmapStep3:"Phase 3: 2022 onwards",
        roadmapStep1Context:"Larix starts from meeting the essential needs of automated Defi lending. At this stage, crypto tokens, stablecoins, and synthetic assets are accepted as collaterals. Risk management and asset security are the cornerstones of Larix.",
        roadmapStep2Context:"Partnering with PYTH network to connect high-fidelity (HiFi) financial markets to the world of decentralized finance (Defi), which enables higher capital utilization of a wider range of conventional financial assets.",
        roadmapStep3Context:"The protocol extends the collateral base to accept Non-Fungible Tokens (NFTs) via peer-to-peer lending across all asset classes. Larix will work with multiple partners to provide secure, customizable, and almost instant liquidity to the real economy.",
        seedRoundInvestor:"Our Investors ",
        partnership:"Partnership",
        audit:"Audit",
    },
    liquidation: {
        liquidation : 'Liquidation',
        account: 'Account',
        amountTip: 'Amount you want to repay in',
        assetToRepay :'Asset To Repay',
        assetsToRepay :'Assets To Repay',
        maxRepayAmount: 'Max Repay Amount',
        assetToSeize : 'Asset To Seize',
        assetsToSeize : 'Assets To Seize',
        maxSeizeAmount : 'Max Seize Amount',
        debtRatio : 'Debt Ratio',
        action : 'Action',
        liquidate : 'Liquidate',
    },
    noticeDialog: {
        notice : 'Notice',
        text : 'Countdown to start mining',
        confirm: 'Confirm'
    },
    pendingDialog: {
        transactionPending: "Pending",
        viewOnEtherscan: "View On Heco",
        confirm: "Confirming the transaction...",
        broadcast: "Transaction broadcast...",
        waitingEvm: "Waiting for EVM results...",
        errorTips:"Transaction Failed",
        checkTips:"Successful Transaction"
      }
  }
  