import {Detail, Obligation, Reserve, TokenAccount} from "@/api/models";
import {WalletAdapter} from "@/api/wallets";
import {Mining} from "@/api/models/state/mining";
import {Token, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {Account, PublicKey, Transaction, TransactionInstruction} from "@solana/web3.js";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {LARIX_TOKEN} from "@/api/utils/ids";

// const LARIX_TOKEN_INNER = new PublicKey("LR4nuUjSGNtRGBFD4tnStDGthJ69Sc8kDUR2ZFQB1Hh")
const LARIX_TOKEN_INNER = LARIX_TOKEN
export const freezeAccount = async (
    wallet: WalletAdapter,
    freeAccount:PublicKey
)=>{
    console.log("freezeAccount.wallet",wallet)
    console.log("freezeAccount.freezeAccount",freeAccount.toString())
    const connection = await getConnection()
    if (wallet === null || wallet === undefined || wallet.publicKey === undefined || wallet.publicKey == null) {
        throw new Error("请先连接钱包")
    }
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];
    instructions.push(
        Token.createFreezeAccountInstruction(TOKEN_PROGRAM_ID, freeAccount, LARIX_TOKEN_INNER, wallet.publicKey, [])
    )
    return await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    );
}

export const unfreezeAccount = async (
    wallet: WalletAdapter,
    freeAccount:PublicKey
)=>{
    const connection = await getConnection()
    if (wallet === null || wallet === undefined || wallet.publicKey === undefined || wallet.publicKey == null) {
        throw new Error("请先连接钱包")
    }
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];
    instructions.push(
        Token.createThawAccountInstruction(TOKEN_PROGRAM_ID, freeAccount, LARIX_TOKEN_INNER, wallet.publicKey, [])
    )
    return await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    );
}