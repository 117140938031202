import {
    Detail,
    Obligation,
    ObligationCollateral,
    Reserve,
    TokenAccount
} from "@/api/models";
import {Account, PublicKey, TransactionInstruction} from "@solana/web3.js";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {depositReserveLiquidity} from "@/api/actions/utils/depositReserveLiquidity";
import {WalletAdapter} from "@/api/wallets";
import {depositObligationCollateral} from "@/api/actions/utils/depositObligationCollateral";
import {U64_MAX} from "@/api/constants/utils";
import {depositMining} from "@/api/actions/utils/depositMing";
import BN from "bn.js";
import {initMining} from "@/api/actions/utils/initMining";
import {Mining} from "@/api/models/state/mining";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";
import {ensureSplAccount} from "@/api/actions/utils/account";
import {Token} from "@solana/spl-token";
import {getMining} from "@/api/provider/miningProvider";
import {getObligation} from "@/api/provider/obligationProvider";
/**
 * <h1>自动质押的存款</h1>
 * 在普通的存款后，会判断用户是否开启过该reserve的抵押，所有开启过，自动将所得Collateral资产进行质押
 * @param source
 * @param liquidityAmount
 * @param wallet
 * @param detailReserve
 * @param destinationCollateralAccount
 */
export async function depositReserve(
    source: TokenAccount,
    liquidityAmount: BN,
    wallet: WalletAdapter,
    detailReserve: Detail<Reserve>,
    allReserve:Detail<Reserve>[],
    lendingProgramId:PublicKey,
mining?:Detail<Mining>,
    destinationCollateralAccount?:PublicKey,
    obligation?:Detail<Obligation>

){

    const connection =await getConnection()
    if (wallet.publicKey == null || wallet.publicKey == undefined){
        throw new Error("Wallet need connected")
    }
    if (mining===undefined){
        const myMinings = await getMining(wallet.publicKey,'userAction')
        if (myMinings.length>0){
            mining = myMinings[0]
        }
    }
    if (obligation==undefined){
        const myObligations = await getObligation(wallet.publicKey,'userAction')
        if (myObligations.length>0){
            obligation = myObligations[0]
        }
    }
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];
    const balanceNeeded = await Token.getMinBalanceRentForExemptAccount(connection)
    const sourcePubkey = ensureSplAccount(
        instructions,
        cleanupInstructions,
        source,
        wallet.publicKey,
        liquidityAmount.toNumber()+balanceNeeded,
        signers
    )
    let miningID:PublicKey
    await refreshReserve(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        detailReserve,
        lendingProgramId
    )
    destinationCollateralAccount = await depositReserveLiquidity(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        sourcePubkey,
        liquidityAmount,
        wallet,
        detailReserve,
        destinationCollateralAccount
    )
    if (mining == undefined){
        miningID = await initMining(
            connection,
            signers,
            instructions,
            cleanupInstructions,
            detailReserve.info.lendingMarket,
            wallet
        )

    } else {

        miningID = mining.pubkey
    }

    if (obligation != undefined) {
        let opened = false
        obligation.info.deposits.map((collateralDetail: ObligationCollateral) => {
            if (collateralDetail.depositReserve.equals(detailReserve.pubkey)) {
                //开启过抵押
                opened = true
            }
        })
        if (opened) {
            refreshReserve(
                connection,
                signers,
                instructions,
                cleanupInstructions,
                detailReserve,
                lendingProgramId
            )
            await depositObligationCollateral(
                connection,
                signers,
                instructions,
                cleanupInstructions,
                wallet,
                U64_MAX,
                destinationCollateralAccount,
                detailReserve,
                obligation,
                lendingProgramId
            )
        } else {
            refreshReserve(
                connection,
                signers,
                instructions,
                cleanupInstructions,
                detailReserve,
                lendingProgramId
            )
            await depositMining(
                connection,
                signers,
                instructions,
                cleanupInstructions,
                destinationCollateralAccount,
                detailReserve,
                U64_MAX,
                wallet,
                detailReserve.info.lendingMarket,
                miningID
            )
        }
    } else {
        await refreshReserve(
            connection,
            signers,
            instructions,
            cleanupInstructions,
            detailReserve,
            lendingProgramId
        )
        await depositMining(
            connection,
            signers,
            instructions,
            cleanupInstructions,
            destinationCollateralAccount,
            detailReserve,
            U64_MAX,
            wallet,
            detailReserve.info.lendingMarket,
            miningID
        )
    }
    return await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    )
}