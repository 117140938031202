import {LendingMarket, LendingMarketParser,Detail} from "../models";
import {getConnection} from "../context/connection";
import {ALL_LENDING_IDS, LENDING_ARRAY, LENDING_ID, LENDING_MARKET} from "@/api/utils/ids";
import {PublicKey} from "@solana/web3.js";
let lendingMarket:Detail<LendingMarket>

export async function getLendingMarket():Promise<Detail<LendingMarket>>{
    if (lendingMarket !== undefined){return lendingMarket}

    const connection = await getConnection()
    const lendingAccountInfo = await connection.getAccountInfo(LENDING_ID);
    if (lendingAccountInfo == null){
        throw new Error("lendingAccountInfos is null")
    }
    lendingMarket = LendingMarketParser(LENDING_ID,lendingAccountInfo)
    return lendingMarket
}
const lendingMarkets = [] as Detail<LendingMarket>[];
export async function getLendingMarkets():Promise<Detail<LendingMarket>[]>{
    if (lendingMarkets.length!==0){return lendingMarkets}
    const connection = await getConnection()
    const lendingAccountInfos = await connection.getMultipleAccountsInfo(ALL_LENDING_IDS);
    if (lendingAccountInfos == null){
        throw new Error("lendingAccountInfos is null")
    }
    lendingAccountInfos.forEach((lendingAccountInfo,index)=>{
        const lendingMarketData = LendingMarketParser(ALL_LENDING_IDS[index],lendingAccountInfo!)
        lendingMarketData.info.lendingType = LENDING_ARRAY[index].lendingName
        lendingMarketData.info.lendingProgramId = LENDING_ARRAY[index].lendingProgramID
        lendingMarkets.push(lendingMarketData)
    })

    return lendingMarkets
}
export function getLendingMarketLocal():Detail<LendingMarket>{
    return LENDING_MARKET
}