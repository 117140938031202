import {
    depositObligationCollateralInstruction,
    Detail,
    LendingMarket,
    Obligation,
    Reserve,
    TokenAccount
} from "@/api/models";
import {getObligation} from "@/api/provider/obligationProvider";
import {WalletAdapter} from "@/api/wallets";
import {Account, PublicKey, TransactionInstruction} from "@solana/web3.js";
import {getConnection, sendTransaction} from "@/api/context/connection";
import {LENDING_PROGRAM_ID} from "@/api/utils/ids";
import {withdrawMining} from "@/api/actions/utils/withdrawMining";
import {U64_MAX} from "@/api/constants/utils";
import {refreshReserve} from "@/api/actions/utils/refreshReserve";
import {initObligation} from "@/api/actions/utils/initObligation";
import {Mining} from "@/api/models/state/mining";
import {getMining} from "@/api/provider/miningProvider";
import openTxDialog from "@/controller/openTxDialog";
import store from "@/store";
import { throwError } from "./utils/throwError";

/**
 *
 * <h1>开启抵押</h1>
 * <h4 collor=“red”>但需要有对应的cToken 才可以开启</h4>
 * 会判断用户是否已经初始化过obligation，没有的话就初始化和把用户所有reserve中的collateral资产转入obligation中；<br/>
 * 如果初始化过obligation则直接转入
 * @param reserve
 * @param wallet
 * @param lendingMarket
 */
export async function intoObligation(
    reserve:Detail<Reserve>,
    wallet:WalletAdapter,
    lendingMarket:Detail<LendingMarket>,
    collAccount:TokenAccount,
    mining:Detail<Mining>,
    allReserve:Detail<Reserve>[],
    moreThanLimit:Boolean,
    lendingProgramId:PublicKey,
    obligation?:Detail<Obligation>,

){
    if (wallet.publicKey == null){
        throw new Error("Wallet need connected")
    }

    if (moreThanLimit){
        throwError()
        return
    }
    openTxDialog()
    const connection = await getConnection();

    // user from account
    const signers: Account[] = [];
    const instructions: TransactionInstruction[] = [];
    const cleanupInstructions: TransactionInstruction[] = [];

    let myObligationAddress:PublicKey
    if (mining===undefined){
        const myMinings = await getMining(wallet.publicKey,'userAction')
        if (myMinings.length>0){
            mining = myMinings[0]
        }
    }
    if (obligation==undefined){
        const myObligations = await getObligation(wallet.publicKey,'userAction')
        if (myObligations.length>0){
            obligation = myObligations[0]
        }
    }
    if (obligation == undefined){
        myObligationAddress = await initObligation(
            connection,
            signers,
            instructions,
            cleanupInstructions,
            wallet,
            lendingMarket
        )
    } else {
        myObligationAddress = obligation.pubkey
    }
    await refreshReserve(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        reserve,
        lendingProgramId
    )
    await withdrawMining(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        mining.pubkey,
        collAccount.pubkey,
        reserve,
        U64_MAX,
        wallet,
        lendingMarket.pubkey
    )  // create approval for transfer transactions

    const [lendingMarketAuthority] = await PublicKey.findProgramAddress(
        [lendingMarket.pubkey.toBuffer()],
        LENDING_PROGRAM_ID,
    );

    const reserveAddress = reserve.pubkey
    refreshReserve(
        connection,
        signers,
        instructions,
        cleanupInstructions,
        reserve,
        lendingProgramId
    )
    instructions.push(
        depositObligationCollateralInstruction(
            U64_MAX,
            collAccount.pubkey,
            reserve.info.collateral.supplyPubkey,
            reserveAddress,
            myObligationAddress,
            lendingMarket.pubkey,
            lendingMarketAuthority,
            // @FIXME: wallet must sign
            wallet.publicKey,
            wallet.publicKey,
            lendingProgramId
        ),
    );
    return  await sendTransaction(
        connection,
        wallet,
        instructions.concat(cleanupInstructions),
        signers,
        true,
    )
}