import store from '../store'
const BigNumber = require("bignumber.js");
BigNumber.config({EXPONENTIAL_AT: 1e9});
import appController from '@/controller/dataErrorController'
import getDetailService from "@/api/services/getDetailService";
import {getLendingMarket,getLendingMarketLocal, getLendingMarkets} from "@/api/provider/lendingMarketProvider";
import {getLendingReserve} from "@/api/provider/lendingReserveProvider";
import {getTokenAccounts} from "@/api/provider/tokenAccountsProvider";
import {getObligation, getObligations} from "@/api/provider/obligationProvider";
import {getMining} from "@/api/provider/miningProvider"
import {getInterest} from '../api/utils/rateModel'
import {getMineRatio} from "@/api/utils/calculateAllMine"

import {Detail, LendingMarket, Obligation, Reserve, TokenAccount} from "@/api/models";
// @ts-ignore
import {BIG_NUMBER_ONE, BIG_NUMBER_ZERO, eX} from "@/utils/helpers";
import {getWallet} from "@/api/context/wallet";
import {Mining} from "@/api/models/state/mining";
import {calculateAllMine} from "@/api/utils/calculateAllMine";
import liquidationController from "@/controller/liquidationController";
import {IEO_LARIX_AMOUNT, REAL_SLOTS_PER_DAY } from '@/api/constants/utils';
import {getIsolationOracle, getOracle} from "@/api/provider/oracleProvider";
import {LENDING_ID} from "@/api/utils/ids";


async function getReserveData(reserveDetail:Detail<Reserve>, wallet:any, userAllTokenAccounts: Map<string,Array<TokenAccount>>, userAllObligations: Array<Detail<Obligation>>,userAllMining:Detail<Mining>[],userObligationIndex:number) {
    const info = reserveDetail.info
    const liquidityMintPubkey = info.liquidity.mintPubkey.toString()
    const collateralMintPubkey = info.collateral.mintPubkey.toString()
    // TODO: 考虑多个账户
    const userLiquidityTokenAccount = userAllTokenAccounts.get(liquidityMintPubkey)?.[0]
    const userCollateralTokenAccount = userAllTokenAccounts.get(collateralMintPubkey)?.[0]
    const borrowTokenAccount = userLiquidityTokenAccount
    const userObligation = userAllObligations?.[userObligationIndex]
    const mining = userAllMining?.[0]
    const symbol = info.liquidity.name
    // @ts-ignore
    const fullName = info.liquidity.fullName
    const tokenName = info.liquidity.mintPubkey.toBase58()
    const liquidityPrice = eX(info.liquidity.marketPrice.toString()||"0",-18)
    const decimals = info.liquidity.mintDecimals
    const walletBalanceInTokenUnit = eX(userLiquidityTokenAccount?.info.amount.toString()||'0',-1*Number(decimals))
    const walletBalance = walletBalanceInTokenUnit.times(liquidityPrice)
    const supplyAmount = eX(
        mining?.info.miningIndices.filter(
            miningIndex=>miningIndex.reserve.equals(reserveDetail.pubkey)
        )[0]?.unCollLTokenAmount.toString()||'0',

        -1*Number(decimals)
    )

    const depositAmount = eX(
        userObligation?.info.deposits.filter(
            liquidity => liquidity.depositReserve.equals(reserveDetail.pubkey)
        )[0]?.depositedAmount.toString() || '0',
        -1 * Number(decimals)
    )

    const collateralTokenBalanceInTokenUnit =
        eX(userCollateralTokenAccount?.info.amount.toString()||'0',-1*Number(decimals))
        .plus(
            supplyAmount
        )
        .plus(
            depositAmount
        )
    const reserveCumulativeBorrowRateWads = eX(info.liquidity.cumulativeBorrowRateWads.toString(),-(18))
    let userCumulativeBorrowRateWads = new BigNumber(userObligation?.info.borrows.filter(item =>{
        return item.borrowReserve.equals(reserveDetail.pubkey)
    })[0]?.cumulativeBorrowRateWads)
    userCumulativeBorrowRateWads = (!userCumulativeBorrowRateWads.isNaN()?eX(userCumulativeBorrowRateWads.toString(),-(18)):new BigNumber(0))
    // @ts-ignore
    const borrowInterestFee = eX(info.config.fees.borrowInterestFeeWad.toString(),-18)

    const totalAvailable = new BigNumber(info.liquidity.availableAmount.toString()).minus(eX(info.liquidity.ownerUnclaimed.toString(),-18)).toString()
    const totalAvailableAmount = totalAvailable.startsWith("-")?BIG_NUMBER_ZERO:eX(totalAvailable,-Number(decimals))

    const compoundedInterestRate = userCumulativeBorrowRateWads.isZero()?new BigNumber(0):reserveCumulativeBorrowRateWads.div(userCumulativeBorrowRateWads)
    const totalAvailableInUsd = totalAvailableAmount.times(liquidityPrice)
    let totalBorrowedAmount = eX(info.liquidity.borrowedAmountWads.toString(),-18)
    // 这里可能剩余一点渣，也就是小于1的一个数，此时直接当成0来处理
    if (totalBorrowedAmount.lt(BIG_NUMBER_ONE)) {
        totalBorrowedAmount = BIG_NUMBER_ZERO
    } else {
        totalBorrowedAmount = totalBorrowedAmount.div(10**decimals)
    }
    const totalBorrowedInUsd = totalBorrowedAmount.times(liquidityPrice)
    const totalLiquidityAmount = new BigNumber(eX(info.liquidity.availableAmount.toString(),-decimals)).plus(totalBorrowedAmount).minus(eX(info.liquidity.ownerUnclaimed.toString(),-18-decimals))
    const totalLiquidityInUsd = totalLiquidityAmount.times(liquidityPrice)


    const mintTotalSupply = eX(info.collateral.mintTotalSupply.toString(),-1*Number(decimals))
    let exchangeRate:typeof BigNumber
    if (mintTotalSupply.isZero() || totalLiquidityAmount.isZero()){
        exchangeRate = BIG_NUMBER_ONE
    } else {
        exchangeRate = mintTotalSupply.div(totalLiquidityAmount)
    }
    const supplyBalanceInTokenUnit = (mintTotalSupply.isZero()||totalLiquidityAmount.isZero())?collateralTokenBalanceInTokenUnit.times(BIG_NUMBER_ONE):collateralTokenBalanceInTokenUnit.div(mintTotalSupply).times(totalLiquidityAmount)
    const supplyBalance = supplyBalanceInTokenUnit.times(liquidityPrice)

    const isEnterMarket = userObligation?.info.deposits.filter(liquidity=>liquidity.depositReserve.equals(reserveDetail.pubkey)).length>0
    const collateralFactor = new BigNumber(info.config.loanToValueRatio.toString()).div(100)

    let borrowBalanceInTokenUnit = eX(userObligation?.info.borrows.filter(liquidity=>liquidity.borrowReserve.equals(reserveDetail.pubkey))[0]?.borrowedAmountWads.toString()||'0',-1*Number(decimals)-18)

    borrowBalanceInTokenUnit = borrowBalanceInTokenUnit.times(compoundedInterestRate)
    const borrowBalance = borrowBalanceInTokenUnit.times(liquidityPrice)
    const {lTokenMiningRatio,borrowMiningRatio}=getMineRatio( info)
    const larixSupplyDistributionRate=lTokenMiningRatio

    const larixBorrowDistributionRate =borrowMiningRatio
    const larixTotalMiningSpeed = eX(new BigNumber(info.bonus.totalMiningSpeed),-6)
    // @ts-ignore
    const supplyLimit = info.liquidity.supplyLimit
    const rateModelArray = getInterest(reserveDetail)

    let logoSource
    try {
        logoSource = require(`../assets/coin/asset_${symbol}.svg`);
    } catch (e) {
        logoSource = require(`../assets/coin/token.svg`);
    }

    // @ts-ignore
    // @ts-ignore
    return {
        reserveDetail,
        symbol,
        fullName,
        tokenName,
        logoSource,
        // underlyingAddress
        liquidityMintPubkey,
        userLiquidityTokenAccount,
        // cTokenAddress
        collateralMintPubkey,
        userCollateralTokenAccount,
        decimals,
        // underlyingPrice
        liquidityPrice,
        // 钱包余额
        walletBalanceInTokenUnit,
        walletBalance,
        collateralTokenBalanceInTokenUnit,
        // 剩余流动性
        totalAvailableAmount,
        totalAvailableInUsd,
        // 总借款
        totalBorrowedAmount,
        totalBorrowedInUsd,
        // 总存款
        totalLiquidityAmount,
        totalLiquidityInUsd,
        // cToken Minted
        mintTotalSupply,
        // 兑换率
        exchangeRate,
        // 用户存款
        supplyBalanceInTokenUnit,
        supplyBalance,
        // 用户借款
        borrowBalanceInTokenUnit,
        borrowBalance,
        // 抵押因子
        collateralFactor,
        // 是否开启抵押
        isEnterMarket,
        supplyDistributionApy:new  BigNumber(0),
        borrowDistributionApy:new BigNumber(0),
        hasBorrowTokenAccount: borrowTokenAccount!==undefined,
        larixTotalMiningSpeed,
        larixSupplyDistributionRate,
        larixBorrowDistributionRate,
        borrowInterestFee,
        //用户的CumulativeBorrowRateWads
        userCumulativeBorrowRateWads,
        //池子的CumulativeBorrowRateWads
        reserveCumulativeBorrowRateWads,
        compoundedInterestRate,
        supplyLimit,
        //算出来的利率模型数组
        rateModelArray
    }
}
// 更新数据主入口
async function updateData(userObligationIndex:number) {
    // 检查用户是否连接钱包
    // @ts-ignore
    let wallet = getWallet()
    if (!wallet) {
        // 当钱包未及时注入时，1秒内持续检测
        for (let i = 0; i < 100; i++) {
            await new Promise<void>(resolve => setTimeout(() => resolve(), 10))
            wallet = getWallet()
            if (wallet) {
                break
            }
        }
        if (!wallet) {
            return
        }
    }
    // const walletPublicKey = wallet.publicKey
    // const walletAddress = walletPublicKey.toBase58()

    let lendingMarket,lendingReserveArray
    try {
        const allRequests = await Promise.all(
            [
                getLendingMarkets(),
                getLendingReserve(),
                // @ts-ignore
                getTokenAccounts(wallet.publicKey),
                // @ts-ignore
                getObligation(wallet.publicKey),
                // @ts-ignore
                getMining(wallet.publicKey),
                getDetailService.getLarixPrice(),
                getDetailService.getMndePrice(),
                getOracle(),
                getIsolationOracle()
            ]
        )
        const allLendingMarket = allRequests[0]
        lendingMarket = allLendingMarket[0]
        lendingReserveArray = allRequests[1]
        // @ts-ignore
        const userAllTokenAccounts = allRequests[2]

        const larixTokenAccount = userAllTokenAccounts.get(lendingMarket.info.mineMint.toString());
        // @ts-ignore
        const userAllObligationArray = allRequests[3]
        // @ts-ignore
        const mining = allRequests[4]
        const larixPrice = allRequests[5].toNumber()
        const mndePrice = allRequests[6].toNumber()
        const mndeYearValue = mndePrice*16483.4*365;
        const userAllObligation = userAllObligationArray?.[userObligationIndex]
        const oracleInfo = allRequests[7]
        const isolationOracleInfo = allRequests[8]
        store.commit('updateOracleInfo',oracleInfo)
        store.commit("updateIsolationOracleInfo",isolationOracleInfo)
        store.commit('updateLendingMarket', lendingMarket)
        // if (actionType!=='autoFresh'){
        store.commit('updateMining', mining?.[0])
        store.commit('updateAllMining', mining)
        store.commit('updateLarixPrice', larixPrice)
        store.commit('updateAllLendingMarket', allLendingMarket)
        store.commit('updateUserObligation', userAllObligation)
        // }
        // if (actionType==='firstUpdateData'||actionType==='userAction'){
        store.commit('updateUserAllObligation', userAllObligationArray)
        // }

        store.commit('updateLendingReserveArray',lendingReserveArray)
        store.commit('updateLarixTokenAccount',larixTokenAccount)

        let allReserveData = await Promise.all(lendingReserveArray.map((item)=>{
            return getReserveData(item, wallet,
                userAllTokenAccounts,
                userAllObligationArray||[],
                mining,userObligationIndex)
        }))
        let userTotalSupply = new BigNumber(0);
        let userTotalBorrow = new BigNumber(0);
        let userBorrowLimit = new BigNumber(0);
        // let userTotalLTokenAmount = new BigNumber(0);
        // let userObligationDepositedLTokenAmount = new BigNumber(0);
        // let userObligationBorrowLTokenAmount = new BigNumber(0);
        // let userMiningLTokenAmount = new BigNumber(0);
        let allMarketTotalSupply = new BigNumber(0);
        let allMarketsTotalBorrow = new BigNumber(0);
        let userLarixReward = new BigNumber(0);
        let yearSupplyInterest = new BigNumber(0);
        let yearBorrowInterest = new BigNumber(0);
        let yearMiningInterest = new BigNumber(0);
        let totalDailyMining = new BigNumber(0);
        let larixCirculation = new BigNumber(0);

        allReserveData = allReserveData.map((res: any) => {
            const utilizationRateRaw = res.totalLiquidityAmount.isZero()?new BigNumber(0):res.totalBorrowedAmount.div(res.totalLiquidityAmount)
            const utilizationRate = utilizationRateRaw.isGreaterThan(1) ? new BigNumber(1) : utilizationRateRaw
            res.borrowApy = res.reserveDetail.info.config.borrowYearCompoundedInterestRate
            res.supplyApy = res.reserveDetail.info.config.supplyYearCompoundedInterestRate
            yearSupplyInterest = yearSupplyInterest.plus(
                res?.supplyBalance.times( res.supplyApy)
            );
            yearBorrowInterest = yearBorrowInterest.plus(
                res?.borrowBalance.times( res.borrowApy)
            );
            if (res.symbol==='mSOL')
            {
                res.mndeSupplyApy = new BigNumber(mndeYearValue).div(res.totalLiquidityInUsd.isZero()?1:res.totalLiquidityInUsd)
                yearSupplyInterest = yearSupplyInterest.plus(
                    res?.supplyBalance.times( res.mndeSupplyApy)
                );
            }
            else {
                res.mndeSupplyApy = new BigNumber(0)
            }
            // const k = utilizationRate

            // 计算 Apy
            // if (item.isCMlpToken) {
            //     // 存款挖矿APR
            //     res.supplyDistributionApy = res.canSpeed.times(28800).times(365).times(canPrice).div(res.marketTotalSupply)
            //     // 借款挖矿APR
            //     res.borrowDistributionApy = new BigNumber(0)
            //     res.mlpApy = getMlpApy(item.cTokenAddress, mdxPrice, item.poolInfo, item.underlyingPrice).times(28800).times(365).times(1.01)
            // } else {
            //     // 存款挖矿APR
            //@ts-ignore
            isNaN(larixPrice)?res.supplyDistributionApy=0:res.supplyDistributionApy = res.larixSupplyDistributionRate.times(res.larixTotalMiningSpeed).times(REAL_SLOTS_PER_DAY).times(365).times(larixPrice).div(res.totalLiquidityInUsd.isZero()?1:res.totalLiquidityInUsd)
            // 借款挖矿APR
            //挖矿每日产出
            res.dailyMining = (res.larixTotalMiningSpeed.times(REAL_SLOTS_PER_DAY))
            //@ts-ignore
            isNaN(larixPrice)?res.borrowDistributionApy=0:res.borrowDistributionApy = res.larixBorrowDistributionRate.times(res.larixTotalMiningSpeed).times(REAL_SLOTS_PER_DAY).times(365).times(larixPrice).div(res.totalBorrowedInUsd.isZero()?1:res.totalBorrowedInUsd)
            res.mlpApy = new BigNumber(0)
            const currentTime = new Date().valueOf()
            //@ts-ignore
            const circulationTime = new BigNumber((currentTime-1631858400000)/3600000)

            //9.17 12:00 :1631851200000
            //9.17 14:00 :1631858400000
            totalDailyMining = totalDailyMining.plus(new BigNumber(res.dailyMining))
        if (!circulationTime.isGreaterThan(0)) larixCirculation = 0
        else {
           larixCirculation =  new BigNumber(totalDailyMining.div(24).times(circulationTime).plus(IEO_LARIX_AMOUNT).toFixed(2))
        }
        // }

            userTotalSupply = userTotalSupply.plus(res.supplyBalance);
            userTotalBorrow = userTotalBorrow.plus(res.borrowBalance);
            userBorrowLimit = userBorrowLimit.plus(
                res.isEnterMarket
                    ? res.supplyBalance.times(res.collateralFactor)
                    : 0
            );
            allMarketTotalSupply = allMarketTotalSupply.plus(res.totalLiquidityInUsd)
            allMarketsTotalBorrow = allMarketsTotalBorrow.plus(res.totalBorrowedInUsd)

            res.utilizationRate = utilizationRate
            return res
        })
        const result = await calculateAllMine(
            //@ts-ignore
            mining == undefined ? undefined : mining[0],
            userAllObligation,
            lendingReserveArray
        )
        const netApy = !userTotalSupply.isZero()
            ? yearSupplyInterest
                .minus(yearBorrowInterest)
                .div(userTotalSupply)
                .toFixed(4)
            : 0
        userLarixReward =  result
        // 计算存借款挖矿年收益
        allReserveData.forEach((item)=>{
            yearMiningInterest = yearMiningInterest.plus(
                item.supplyBalanceInTokenUnit.times(item.liquidityPrice).times(item.supplyDistributionApy)
            ).plus(
                item.borrowBalanceInTokenUnit.times(item.liquidityPrice).times(item.borrowDistributionApy)
            )
        })
        // 计算存借款挖矿APY
        const miningApy = !userTotalSupply.isZero()
            ? yearMiningInterest
                .div(userTotalSupply)
                .toFixed(4)
            : 0
        const netRate = new BigNumber(netApy).plus(miningApy)

        store.commit('updateUserTotalSupply',userTotalSupply)
        store.commit('updateUserTotalBorrow',userTotalBorrow)
        store.commit('updateUserLarixReward',userLarixReward)
        store.commit('updateUserBorrowLimit',userBorrowLimit)
        store.commit('updateTotalDailyMining',totalDailyMining)
        store.commit('updateNetRate',netRate)
        store.commit('updateAllMarketTotalSupply', allMarketTotalSupply)
        store.commit('updateAllMarketTotalBorrow', allMarketsTotalBorrow)
        store.commit('updateUserLarixReward', userLarixReward)
        store.commit('updateAllReservesDetails',allReserveData)
        store.commit('updateIsLoadingInfo',false)
        store.commit('updateLarixCirculation',larixCirculation)

    } catch (e) {
        appController.controlDataError(10)
        console.log(e)

    } finally {
        if (lendingMarket&&lendingReserveArray) {
            await Promise.all([
                liquidationController.updateLiquidationData(lendingMarket.pubkey, lendingReserveArray)
            ])
        }

    }


}

export default {
    updateData,
}
